import React from 'react';
import styles from './detailsInformation.module.scss';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import checkList from '../../../assets/logo/checklist.svg';
import Card from 'components/Card/Card';

const Info = ({ title, value }) => (
  <div className={styles.info}>
    <span>{title}</span>
    <p>{value}</p>
  </div>
);

const DetailsInformation = ({
  userInfo,
  doctorInfo,
  lastCareplanDate,
  careplanId,
  carePlanHistory,
}) => {
  const navigate = useNavigate();
  const completedCarePlans = carePlanHistory
    .filter((cp) => cp.status === 'COMPLETED')
    .sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
    )
    .slice(0, 3);

  console.log({
    userInfo,
    doctorInfo,
    lastCareplanDate,
    careplanId,
    carePlanHistory,
  });

  return (
    <div className={styles.root}>
      <div style={{ width: '49%' }}>
        <Card
          title={'General Information'}
          showViewAll={false}
          content={
            <div className={styles.generalInfo}>
              <div>
                <h1 className={styles.subHeader}>Address</h1>
                <div
                  className={`${styles.information} ${styles.addressContainer}`}
                >
                  <Info title="Street" value={userInfo?.street} />
                  <Info title="Suburb" value={userInfo?.city} />
                  <Info title="Country" value={userInfo?.country} />
                  <Info title="Postal Code" value={userInfo?.postalCode} />
                </div>
              </div>

              <h1 className={styles.subHeader}>Other Information</h1>
              <div className={styles.information}>
                <Info
                  title="Date of Birth"
                  value={`${moment(userInfo?.dateOfBirth).format(
                    'LL'
                  )} (${moment().diff(userInfo?.dateOfBirth, 'years')} Years)`}
                />

                <Info title="Gender" value={userInfo?.sex} />

                <Info title="Medicare Id" value={userInfo?.medicareNum} />
                <Info title="IRN" value={userInfo?.medicareReferralNumber} />

                <Info
                  title="Most Recent K10 Score"
                  value={userInfo?.recentKTenScore}
                />
                <Info title="Phone" value={userInfo?.phoneNumber} />
                <Info title="Email address" value={userInfo?.email} />
              </div>
            </div>
          }
        />
      </div>
      <div style={{ width: '49%' }}>
        <div className={styles.right}>
          <Card
            showViewAll={false}
            icon={checkList}
            title={'Patient Care Plan'}
            content={
              <div className={styles.patientContainer}>
                {careplanId && (
                  <div className={styles.cpContainer}>
                    <h3 className={styles.headerText}>Current Care Plan</h3>

                    <div className={styles.creator}>
                      <div className={styles.nameContainer}>
                        <div className={styles.name}>
                          <div>
                            <p>
                              By Dr.{' '}
                              {`${doctorInfo?.practitioner?.firstName}  
                          ${doctorInfo?.practitioner?.lastName}`}
                            </p>
                            <span> </span>
                          </div>
                          <span className={styles.date}>
                            CREATED ON {moment(lastCareplanDate).format('LL')}
                          </span>
                        </div>
                        <div className={styles.viewCarePlanBtn}>
                          <Button
                            label={'View'}
                            type={'button'}
                            onClick={() =>
                              navigate(
                                `/dashboard/careplan-summary/${careplanId}`
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className={styles.history}>
                        <table>
                          <thead>
                            <tr className="table-head">
                              <th
                                style={{
                                  textAlign: 'start',
                                  fontFamily: 'Avenir',
                                }}
                              >
                                DATE
                              </th>
                              <th
                                style={{
                                  textAlign: 'start',
                                  fontFamily: 'Avenir',
                                }}
                              >
                                PRACTITIONER
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {completedCarePlans?.map((plan) => (
                              <tr
                                className={styles['table-row']}
                                key={plan._id || plan.id}
                              >
                                <td style={{ textAlign: 'start' }}>
                                  {moment(plan.createdAt).format('LL')}
                                </td>
                                <td
                                  style={{ textAlign: 'start' }}
                                >{`${plan.practitioner?.firstName} ${plan.practitioner?.lastName}`}</td>
                                <td>
                                  <div>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/dashboard/careplan-summary/${
                                            plan._id || plan.id
                                          }`
                                        )
                                      }
                                      className={styles['table-details-button']}
                                    >
                                      View
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsInformation;
