import React, { useState } from 'react';
import styles from './addHomework.module.scss';
import addIcon from '../../../../assets/logo/add.svg';

import CustomSelectDropdown from '../../../CustomSelectDropdown/CustomSelectDropdown';
import { periodData } from 'utils/constants';

const AddHomework = ({ homework, setHomework, close }) => {
  const handleAddFields = () => {
    setHomework([...homework, { task: '', duration: '' }]);
  };

  const handleChangeValues = (i, value, name) => {
    const newFormValues = [...homework];
    newFormValues[i] = { ...newFormValues[i], [name]: value };
    setHomework(newFormValues);
  };
  const handleDeleteHomework = (id) => {
    const filteredFormValue = homework.filter((item, index) => index !== id);
    setHomework(filteredFormValue);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Homework and Tasks</h1>
        <span>Prescribe assignments for this patient.</span>
      </div>
      {homework.map((element, index) => (
        <div className={styles.homeworkRoot} key={index}>
          <div className={styles.homeworkHeader}>
            <h1>Homework {index + 1}</h1>

            <span
              onClick={() => handleDeleteHomework(index)}
              className={styles.delete}
            >
              delete
            </span>
          </div>
          <div className={styles.homework}>
            <div className={styles.inputField}>
              <label>Task</label>
              <input
                onChange={(e) =>
                  handleChangeValues(index, e.target.value, 'task')
                }
                name="task"
                value={element.task}
              />
            </div>
            <div className={styles.duration}>
              <CustomSelectDropdown
                value={element?.duration}
                name="duration"
                label="Duration"
                className={styles.dropdown}
                data={periodData}
                onChange={(value) =>
                  handleChangeValues(index, value, 'duration')
                }
              />
            </div>
          </div>
        </div>
      ))}
      <div className={styles.footer}>
        <div onClick={handleAddFields} className={styles.add}>
          <img src={addIcon} alt="" />
          <span>Add Homework</span>
        </div>
        <button onClick={close} className={styles.done}>
          Done
        </button>
      </div>
    </div>
  );
};

export default AddHomework;
