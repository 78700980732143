import { api } from '../../api';
import {
  kycVerification,
  loginSuccess,
  signUpSuccess,
} from '../slices/authSlice';

// Async action creator for login API call
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const { data } = await api.post('/practitioners/login', {
        email,
        password,
      });
      if (data.data.token) {
        await localStorage.setItem('token', JSON.stringify(data.data.token));
      }
      return dispatch(loginSuccess(data.data));
    } catch (error) {
      throw error;
    }
  };

export const register = (data) => async (dispatch) => {
  try {
    const response = await api.post('/practitioners/register', data);
    dispatch(signUpSuccess(response.data.data));
  } catch (error) {
    throw error;
  }
};
export const verifyKyc = (data) => async (dispatch) => {
  try {
    const response = await api.post('/practitioners/create-kyc', data);
    dispatch(kycVerification(response.data.data));
    return response.data.data.kycStatus;
  } catch (error) {
    throw error;
  }
};
