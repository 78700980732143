export const getLastCompletedStepNumber = (careplan) => {
  const completionStatus = careplan?.stepsCompleted || {};

  const relevantKeys = [
    'stepOneCompleted',
    'stepTwoCompleted',
    'stepThreeCompleted',
    'stepFourCompleted',
    'stepFiveCompleted',
  ];

  const firstIncompleteStep = relevantKeys.find(
    (step) => !completionStatus[step]
  );
  const stepNumberMapping = {
    stepOneCompleted: 1,
    stepTwoCompleted: 2,
    stepThreeCompleted: 3,
    stepFourCompleted: 4,
    stepFiveCompleted: 5,
  };

  const stepNumber = stepNumberMapping[firstIncompleteStep];
  return stepNumber;
};

const deepArrayCompare = (arr1, arr2) => {
  const arrayChanges = [];

  for (let i = 0; i < arr1.length; i++) {
    const changes = deepCompare(arr1[i], arr2[i]);
    if (Object.keys(changes).length > 0) {
      arrayChanges.push(changes);
    }
  }

  return arrayChanges;
};

export const deepCompare = (obj1, obj2) => {
  if (obj1 === obj2) return {}; // If the objects are the same, no changes

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 !== obj2 ? { value: obj1 } : {}; // Changed value object
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const changedValues = {};

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        // Handle array comparison
        const arrayChanges = deepArrayCompare(obj1[key], obj2[key]);
        if (arrayChanges.length > 0) {
          changedValues[key] = arrayChanges;
        }
      } else if (
        typeof obj1[key] === 'object' &&
        typeof obj2[key] === 'object'
      ) {
        // Handle nested object comparison
        const nestedChanges = deepCompare(obj1[key], obj2[key]);
        if (Object.keys(nestedChanges).length > 0) {
          changedValues[key] = nestedChanges;
        }
      } else {
        changedValues[key] = obj1[key];
      }
    }
  }

  return changedValues;
};
