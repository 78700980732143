import AccountVerificationForm from '../components/AccountVerificationForm';
import OnboardingLayout from '../layouts/OnboardingLayout';
import './AccountVerification.scss';

const AccountVerification = () => {
  return (
    <OnboardingLayout>
      <AccountVerificationForm />
    </OnboardingLayout>
  );
};

export default AccountVerification;
