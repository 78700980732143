import React from 'react';
import styles from './modalInput.module.scss';

const ModalInput = ({
  label,
  name,
  type,
  value,
  onChange,
  className,
  ...props
}) => {
  return (
    <div className={`${styles.modalInputContainer} ${className}`}>
      <label>{label}</label>
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

export default ModalInput;
