import React from 'react';
import './OnboardingLayout.scss';
import carePlan from '../assets/logo/cp.svg';
import TermsAndConditions from 'components/TermsAndConditions';
import { useLocation } from 'react-router-dom';

const OnboardingLayout = ({ children }) => {
  const location = useLocation();

  return (
    <div className="onboarding-layout">
      <div className="header">
        {location.pathname !== '/' ? (
          <img src={carePlan} alt="careplan logo" />
        ) : null}
      </div>
      <div className="main-content-wrapper">
        <div>
          <div className="content-wrapper">
            <main className="content">{children}</main>
            <div className="terms_footer">
              <TermsAndConditions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingLayout;
