export const reductionLevelData = [
  { key: '10%', value: '10%', label: '10%' },
  { key: '20%', value: '20%', label: '20%' },
  { key: '30%', value: '30%', label: '30%' },
  { key: '40%', value: '40%', label: '40%' },
  { key: '50%', value: '50%', label: '50%' },
  { key: '60%', value: '60%', label: '60%' },
  { key: '70%', value: '70%', label: '70%' },
  { key: '80%', value: '80%', label: '80%' },
  { key: '90%', value: '90%', label: '90%' },
  { key: '100%', value: '100%', label: '100%' },
];

export const periodData = [
  { key: '1week', value: '2 weeks', label: '2 weeks' },
  { key: '5week', value: '4 weeks', label: '4 weeks' },
  { key: '8week', value: '6 weeks', label: '6 weeks' },
  { key: '8week', value: '8 weeks', label: '8 weeks' },
  { key: '8week', value: '10 weeks', label: '10 weeks' },
];
