import React from 'react';
import './timeScroll.scss';
import moment from 'moment';

const TimeScrollComponent = ({ selectedTime, setSelectedTime }) => {
  const generateTimeArray = () => {
    const timeArray = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`;
        const label = `${hour % 12 === 0 ? 12 : hour % 12}:${
          minute === 0 ? '00' : minute
        } ${hour < 12 ? 'am' : 'pm'}`;
        timeArray.push({ key: time, label: label });
      }
    }
    return timeArray;
  };

  const timeArray = generateTimeArray();

  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  return (
    <div className="time-scroll-container">
      <div className="time-list">
        {timeArray.map((time) => (
          <div
            key={time.key}
            className={`time-item ${
              moment(selectedTime, 'HH:mm').isSame(moment(time.key, 'HH:mm'))
                ? 'active'
                : ''
            }`}
            onClick={() => handleTimeClick(time.key)}
          >
            {time.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeScrollComponent;
