import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import carePlanReducer from './slices/carePlanSlice';
import currentCarePlanReducer from './slices/currentCarePlan';
import carePlanSummaryReducer from './slices/carePlanSummary';
import medicareClaimsReducer from './slices/medicareClaimSlice';
import accountReducer from './slices/accountSlice';
import billingsReducer from './slices/billingSlices';

const rootReducer = combineReducers({
  auth: authReducer,
  carePlan: carePlanReducer,
  account: accountReducer,
  currentCarePlan: currentCarePlanReducer,
  carePlanSummary: carePlanSummaryReducer,
  medicareClaims: medicareClaimsReducer,
  billings: billingsReducer,
});

export default rootReducer;
