import './Button.scss';
import { ThreeDots } from 'react-loader-spinner';

const Button = ({ label, type, disabled, isLoading, onClick, style }) => {
  const labelToDisplay = isLoading ? (
    <ThreeDots
      height="30"
      width="30"
      radius="5"
      color="white"
      wrapperClass="button-container-spinner"
    />
  ) : (
    label
  );
  return (
    <div className="button-container">
      <button
        className={`${disabled || isLoading ? 'disabled' : ''}`}
        type={type}
        onClick={disabled ? null : onClick}
        style={style}
      >
        {labelToDisplay}
      </button>
    </div>
  );
};

export default Button;
