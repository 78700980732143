import LoginForm from '../components/LoginForm';
import OnboardingLayout from 'layouts/OnboardingLayout';
import './LoginPage.scss';

const LoginPage = () => {
  return (
    <div>
      <OnboardingLayout>
        <LoginForm />
      </OnboardingLayout>
    </div>
  );
};

export default LoginPage;
