import React, { useEffect, useState } from 'react';
import styles from './createCareSchdule.module.scss';
import avatar from '../../../assets/logo/userAvatar.svg';
import GoBackButton from '../../GoBackButton/GoBackButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleNextPlanStep } from '../../../redux/slices/carePlanSlice';
import Modal from '../../Modal/Modal';
import AddHomework from './homework/AddHomework';
import ScheduleRequest from './request/ScheduleRequest';
import CreateAppointment from './appointment/CreateAppointment';
import {
  setCurrentCarePlan,
  updateHomeWork,
  updateRequest,
} from 'redux/slices/currentCarePlan';
import moment from 'moment';
import { updateCarePlan } from 'redux/actions/carePlanAction';
import { toast } from 'react-toastify';
import Button from 'components/Button';

const CreateCareSchedule = () => {
  const {
    homework: homeworkFromStore,
    requests: requestFromStore,
    assessment,
    appointment,
    patient,
    _id,
    hasStripePaymentBeenMade,
  } = useSelector((state) => state.currentCarePlan) || {};
  const currentCarePlan = useSelector((state) => state.currentCarePlan);
  const [homework, setHomework] = useState(homeworkFromStore || []);
  const [requests, setRequest] = useState(requestFromStore || []);
  const [loading, setLoading] = useState(false);
  const [openHomeworkModal, setOpenHomeworkModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(handleNextPlanStep({ step: 2 }));
  }, []);

  const continueHandler = async () => {
    try {
      const payload = {
        assessment,
        userId: patient?._id,
        careplan: {
          ...currentCarePlan,
          assessment: currentCarePlan.assessment?._id,
          homework,
          requests,
          appointment,
          stepsCompleted: {
            ...currentCarePlan.stepsCompleted,
            stepThreeCompleted: true,
          },
        },
      };

      setLoading(true);
      const { data } = await updateCarePlan(_id, payload);
      dispatch(
        setCurrentCarePlan({
          ...data.data,
        })
      );

      setLoading(false);
      dispatch(handleNextPlanStep({ step: 3 }));

      navigate('/create-care-plan/billing');
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className={styles.root}>
      <section className={styles.topName}>
        <div className={styles.image}>
          <img src={avatar} alt="" />
          <div>
            <h1>{patient?.firstName + ' ' + patient?.lastName}</h1>
            <span>Care Plan</span>
          </div>
        </div>
        <GoBackButton
          to={-1}
          onClick={() => navigate('/create-care-plan/plan')}
        />
      </section>
      <section className={styles.schedule}>
        <div className={styles.header}>
          <h1>Schedule</h1>
          <span>
            Review long and short term goal with patient, and prescribe
            interventions
          </span>
        </div>
        <div className={styles.homework}>
          <div className={styles.title}>
            <h1>Homework/Tasks</h1>
            {homework.length > 0 && (
              <h2
                className={styles.editButton}
                onClick={() => setOpenHomeworkModal(true)}
                role="button"
              >
                Edit Task
              </h2>
            )}
          </div>
          {homework.length ? (
            <div className={styles.tasks}>
              {homework.map((data, index) => (
                <div key={index} className={styles.homework}>
                  <h1>Task {index + 1}</h1>
                  <p>{data.task}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.content}>
              <span>No homework given yet</span>
              <button onClick={() => setOpenHomeworkModal(true)}>
                Add Homework
              </button>
            </div>
          )}
        </div>
        <div className={styles.bottom}>
          <div className={styles.orders}>
            <div className={styles.title}>
              <h1>Request/Orders</h1>
              <h2
                onClick={() => setOpenRequestModal(true)}
                className={styles.editButton}
                role="button"
              >
                Edit Request
              </h2>
            </div>
            {requests.length ? (
              <div className={styles.requests}>
                {requests.map((data, index) => (
                  <div key={index} className={styles.request}>
                    <div>
                      <p>{data}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.content}>
                <span>No additional requests yet.</span>
                <button onClick={() => setOpenRequestModal(true)}>
                  Add Request
                </button>
              </div>
            )}
          </div>
          <div className={styles.orders}>
            <div className={styles.title}>
              <h1>Appointment</h1>
              <h2
                onClick={() => setOpenAppointmentModal(true)}
                className={styles.editButton}
                role="button"
              >
                Edit Appointment
              </h2>
            </div>
            {appointment?.appointmentDate ? (
              <div className={styles.appointment}>
                <h1>
                  {moment(appointment?.appointmentDate)
                    .utc()
                    .format('dddd, MMMM Do YYYY h:mm A')}
                </h1>
                <a href={appointment?.meetingLink}>
                  {appointment?.meetingLink}
                </a>
                {/* <p>Duration (1 hour)</p> */}
              </div>
            ) : (
              <div className={styles.content}>
                <span>No No appointment set yet</span>
                <button onClick={() => setOpenAppointmentModal(true)}>
                  Add Appointment
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.next}>
          <Button
            isLoading={loading}
            onClick={continueHandler}
            label={'Continue'}
            type={'button'}
          />
        </div>
      </section>
      <Modal
        isOpen={openHomeworkModal}
        onClose={() => setOpenHomeworkModal(false)}
        children={
          <AddHomework
            close={() => {
              dispatch(updateHomeWork({ homework }));
              setOpenHomeworkModal(false);
            }}
            homework={homework}
            setHomework={setHomework}
          />
        }
      />
      <Modal
        isOpen={openRequestModal}
        onClose={() => setOpenRequestModal(false)}
        children={
          <ScheduleRequest
            close={() => {
              dispatch(updateRequest({ requests }));
              setOpenRequestModal(false);
            }}
            requests={requests}
            setRequests={setRequest}
          />
        }
      />
      <Modal
        isOpen={openAppointmentModal}
        onClose={() => setOpenAppointmentModal(false)}
        children={
          <CreateAppointment close={() => setOpenAppointmentModal(false)} />
        }
      />
    </div>
  );
};

export default CreateCareSchedule;
