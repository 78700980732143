import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import './DashboardLayout.scss';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetBillingType } from 'redux/slices/currentCarePlan';

const DashboardLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBillingType());
  }, [dispatch]);

  return (
    <div className="dashboard-layout-container">
      <Navbar />
      <div className="dashboard-layout-children">
        <div className="dashboard-layout-inner-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
