import { useState } from 'react';
import styles from './longTermGoal.module.scss';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { setLongTermGoals } from 'redux/slices/currentCarePlan';

const LongTermGoal = ({
  closeModal,
  setLongTermGoal,
  longTermGoal,
  assessment,
}) => {
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const inputText = event.target.value;

    setLongTermGoal(inputText);
  };
  const onClose = () => {
    dispatch(
      setLongTermGoals({
        assessment: { ...assessment, longTermGoal: longTermGoal?.trim() },
      })
    );
    closeModal();
  };
  return (
    <div className={styles.root}>
      <h1>Add long term goal</h1>
      <form>
        <label>Add a mental health target for the next 1-2 years.</label>
        <textarea
          onChange={handleInputChange}
          rows={9}
          placeholder="Write something"
          value={longTermGoal}
        />

        <Button
          label={'Set long term goal'}
          type={'button'}
          onClick={onClose}
        />
      </form>
    </div>
  );
};

export default LongTermGoal;
