import React from 'react';
import { Select } from 'antd';
import styles from './customSelectDropdown.module.scss';
import { AiFillCaretDown } from 'react-icons/ai';

const { Option } = Select;

const CustomSelectDropdown = ({
  label,
  value,
  onChange,
  name,
  showSearch,
  mode,
  data = [],
  placeholder = '',
  showIcon = false,
  defaultValue,
  icon = <AiFillCaretDown size={18} color="black" />,
}) => {
  return (
    <div className={styles['ant-select-selector']}>
      <label className={styles['custom-select-label']}>{label}</label>
      <Select
        suffixIcon={showIcon ? icon : null}
        defaultValue={defaultValue}
        aria-hidden="true"
        mode={mode}
        defaultActiveFirstOption={true}
        showSearch={showSearch}
        style={{ width: '100%' }}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      >
        {data.map((data, index) => (
          <Option key={data?.id || index} value={data.value}>
            {data.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CustomSelectDropdown;
