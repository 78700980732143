import Card from 'components/Card/Card';
import Avatar from '../assets/logo/userAvatar.svg';
import DashboardProfile from '../components/DashboardProfile/DashboardProfile';
import DashboardUsersTable from '../components/DashboardUsersTable/DashboardUsersTable';
import './DashboardOverview.scss';
import { useSelector } from 'react-redux';
import { getGreeting } from 'utils/greeting';
import CustomTable from 'components/DashboardUsersTable/CustomTable';
import { fetcher } from 'api';
import useSWR from 'swr';
import GeneralList from 'components/Tables/GeneralList';
import moment from 'moment';
import defaultProfilePicture from '../assets/logo/userAvatar.svg';

const patientColumns = [
  { label: 'Patient', key: 'name' },
  { label: 'age', key: 'age' },
  { label: 'Location', key: 'location' },
];
const billingColumns = [
  { label: 'Item Numbers', key: 'itemNumber' },
  { label: 'Patient Name', key: 'patientName' },
  { label: 'Amount', key: 'amount' },
];
const DashboardOverview = () => {
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isValidating } =
    useSWR(['/practitioners/dashboard'], fetcher) || {};

  return (
    <div className="dashboard-overview-container">
      <DashboardProfile />
      <div className="dashboard-overview-card-container">
        <div className="overview-card-left">
          <Card
            viewAllLink="/dashboard/patients"
            title="Patients List"
            content={
              <GeneralList
                baseRedirectUrl="/dashboard/user-details/"
                listType={'patients'}
                columns={patientColumns}
                data={(data?.patients ?? []).map((pa) => ({
                  id: pa?._id,
                  name: {
                    imageSrc: defaultProfilePicture,
                    alt: 'user',
                    text: `${pa.firstName} ${pa.lastName}`,
                  },
                  age: moment().diff(pa?.dateOfBirth, 'years'),
                  location: pa?.city,
                }))}
              />
            }
          />
        </div>
        <div className="overview-card-right">
          <Card
            title="Most Recent Billings"
            viewAllLink={'/dashboard/billing'}
            content={
              <GeneralList
                baseRedirectUrl="/dashboard/billing"
                listType={'billing'}
                columns={billingColumns}
                data={(data?.mostRecentBilling ?? []).map((pa) => ({
                  id: pa?._id,
                  itemNumber: pa?.itemNumber,
                  patientName: pa?.patientName,
                  amount: '$' + pa?.amount,
                  locationId: pa?.locationId,
                  claimId: pa?.claimId,
                }))}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
