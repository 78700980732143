import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { fetchCarePlanSummary } from 'redux/actions/patientAction';

const initialState = {
  loading: false,
  error: null,
  assessment: {
    shortTermGoal: {
      message: '',
      reductionLevel: '',
      period: '',
    },
    longTermGoal: '',
    k10questions: {
      first: 0,
      second: 0,
      third: 0,
      fourth: 0,
      fifth: 0,
      sixth: 0,
      seventh: 0,
      eighth: 0,
      ninth: 0,
      tenth: 0,
    },
    level: '',
    rating: 0,
    score: 0,
    stressors: {},
  },
  requests: [],
  patientNotes: [],
  therapy: {
    hasSafetyPlanning: null,
    therapyType: '',
    otherInterventions: '',
  },
  homework: [],
  medication: [],
  patientNote: '',
  patientNoteDate: null,
  rating: null,
  riskToOthers: '',
  riskToSelf: '',

  _id: '',
  user: {
    city: '',
    country: '',
    dateOfBirth: '',
    email: '',
    firstName: '',
    lastName: '',
    medicareNum: '',
    medicareReferralNumber: '',
    phoneNumber: '',
    postalCode: '',
    recentKTenScore: 0,
    sex: '',
    state: '',
    id: '',
  },
  assessmentOverview: '',
  careTeam: [],
  careplanHistory: [],
  asrs: [],
};

const carePlanSummary = createSlice({
  name: 'carePlanSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarePlanSummary.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCarePlanSummary.fulfilled, (state, action) => {
        state = action.payload;
        state.loading = false;

        const { user, assessment } = action.payload;
        const { stressors, rating, level, score } = assessment || {};
        // Calculate assessment overview

        const genderPronoun =
          user?.sex?.toLowerCase() === 'male' ? 'He' : 'She';
        const genderPronounSecondPerson =
          user?.sex?.toLowerCase() === 'male' ? 'His' : 'Her';
        const anxietyLevel = level?.split('-')[1]?.trim();

        const transformedStressors = Object.keys(stressors).filter(
          (key) => stressors[key]
        );
        state.assessmentOverview = `${user.firstName} is a ${moment().diff(
          user?.dateOfBirth,
          'years'
        )} year old ${user.sex} who lives in ${
          user?.city
        }. ${genderPronoun} has a K10 score of ${score} indicating a ${anxietyLevel} level of Anxiety and Depression. They rate their level of coping at ${rating}. ${genderPronounSecondPerson} main stressors include ${transformedStressors?.join(
          ' , '
        )}.`;

        return state;
      })
      .addCase(fetchCarePlanSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCarePlanSummary } = carePlanSummary.actions;

export default carePlanSummary.reducer;
