import React from 'react';
import styles from './customRadioButton.module.scss';

const CustomRadio = ({ options = [], name, onChange, value }) => {
  return (
    <div>
      {options.map((option) => (
        <label key={option.value} className={styles['radio-container']}>
          <input
            type="radio"
            name={name}
            value={value}
            checked={value === option.value ? true : false}
            onChange={() => onChange(option.value)}
            className={styles['custom-radio']}
          />
          <span className={styles['radio-indicator']}></span>
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default CustomRadio;
