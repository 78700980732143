import React from 'react';

import goalIcon from '../../../assets/logo/dart.svg';
import checkList from '../../../assets/logo/checklist.svg';
import styles from './detailsGoal.module.scss';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Button from 'components/Button';
import Card from 'components/Card/Card';

const DetailsGoal = ({ longTermGoals, shortTermGoals, asrs }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div style={{ width: '49%' }}>
        <Card
          title={'Goals'}
          showViewAll={false}
          icon={goalIcon}
          content={
            <div className={styles.left}>
              <div className={styles.leftContent1}>
                <h2>SHORT TERM GOALS</h2>
                <span>{shortTermGoals}</span>
              </div>
              <div className={styles.leftContent2}>
                <h2>LONG TERM GOALS</h2>
                <span>{longTermGoals}</span>
              </div>
            </div>
          }
        />
      </div>

      <div style={{ width: '49%' }}>
        <Card
          title={'ASRS'}
          showViewAll={false}
          icon={checkList}
          content={
            <div className={styles.right}>
              {asrs?.map((ar, index) => (
                <div>
                  <div className={styles.nameContainer}>
                    <div className={styles.name}>
                      <p>{moment(ar.createdAt).format('MMMM-DD-YYYY')}</p>

                      <span className={styles.date}>
                        Created on {moment(ar.createdAt).format('LL')}
                      </span>
                    </div>
                    <div className={styles.viewCarePlanBtn}>
                      <Button
                        label={'View'}
                        type={'button'}
                        onClick={() =>
                          navigate(`/dashboard/asrs-questions?id=${ar._id}`)
                        }
                      />
                    </div>
                  </div>
                  <div />
                </div>
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default DetailsGoal;
