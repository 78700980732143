import React from 'react';
import './icon.scss';

const BillingIcon = () => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    className="icon-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7503 12.4167H19.5003V4.83333C19.5003 4.54602 19.3862 4.27047 19.183 4.0673C18.9799 3.86414 18.7043 3.75 18.417 3.75H3.25033C2.96301 3.75 2.68746 3.86414 2.48429 4.0673C2.28113 4.27047 2.16699 4.54602 2.16699 4.83333V20C2.16699 21.7918 3.62516 23.25 5.41699 23.25H20.5837C22.3755 23.25 23.8337 21.7918 23.8337 20V13.5C23.8337 13.2127 23.7195 12.9371 23.5164 12.734C23.3132 12.5308 23.0376 12.4167 22.7503 12.4167ZM5.41699 21.0833C5.12967 21.0833 4.85412 20.9692 4.65096 20.766C4.4478 20.5629 4.33366 20.2873 4.33366 20V5.91667H17.3337V20C17.3337 20.3803 17.3997 20.7453 17.5189 21.0833H5.41699ZM21.667 20C21.667 20.2873 21.5529 20.5629 21.3497 20.766C21.1465 20.9692 20.871 21.0833 20.5837 21.0833C20.2963 21.0833 20.0208 20.9692 19.8176 20.766C19.6145 20.5629 19.5003 20.2873 19.5003 20V14.5833H21.667V20Z"
      className="icon-svg"
    />
    <path
      d="M6.5 8.08325H15.1667V10.2499H6.5V8.08325ZM6.5 12.4166H15.1667V14.5833H6.5V12.4166ZM11.9167 16.7499H15.1667V18.9166H11.9167V16.7499Z"
      className="icon-svg"
    />
  </svg>
);

export default BillingIcon;
