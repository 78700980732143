import React, { useEffect, useState } from 'react';
import styles from './appointment.module.scss';
import TimeScrollComponent from './timeScroll/TimeScroll';
import Calendar from 'react-calendar';
import Button from 'components/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import moment, { utc } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointment } from 'redux/slices/currentCarePlan';

const CreateAppointment = ({ close }) => {
  const appointment = useSelector((state) => state.currentCarePlan.appointment);
  const [date, setDate] = useState(
    new Date(appointment?.appointmentDate || Date.now())
  );
  const [appointmentDateTime, setAppointmentDateTime] = useState(
    appointment?.appointmentDate || new Date()
  );
  const [meetingLink, setMeetingLink] = useState(
    appointment?.meetingLink || ''
  );
  const [selectedTime, setSelectedTime] = useState(
    moment(appointment?.appointmentDate || new Date())
      .utc()
      .format('HH:mm')
  );

  // ...

  useEffect(() => {
    const timeDate = new Date(`1970-01-01T${selectedTime}Z`);
    // Construct datetime
    const dateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeDate.getHours(),
      timeDate.getMinutes()
    );

    // Convert to ISO string
    const isoDateTime = dateTime.toISOString();
    setAppointmentDateTime(isoDateTime);
  }, [date, selectedTime]);

  const dispatch = useDispatch();

  const handleAppointment = () => {
    dispatch(
      updateAppointment({
        appointment: {
          appointmentDate: appointmentDateTime,
          meetingLink: meetingLink,
        },
      })
    );
    close();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Add Appointment</h1>
        <span>Set a date and time for appointment with patient</span>
      </div>
      <div className={styles.appointment}>
        <div className={styles.calendar}>
          <Calendar value={date} onChange={(d) => setDate(d)} />
        </div>
        <div>
          <h1 className={styles.time}>
            {moment(new Date()).utc().format('dddd, MMMM Do YYYY')}
          </h1>
          <TimeScrollComponent
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
        </div>
      </div>
      <div className={styles.meetingLink}>
        <CustomInput
          name={'meetingLink'}
          label={'Meeting Link'}
          onChange={setMeetingLink}
          value={meetingLink}
        />
      </div>
      <div className={styles.details}>
        <h1>Appointment Details</h1>
        <a href={meetingLink}>{meetingLink}</a>
        <p>
          {moment(appointmentDateTime)
            .utc()
            .format('dddd, MMMM Do YYYY, h:mm a')}
        </p>
      </div>

      <Button
        onClick={handleAppointment}
        label={'Set Appointment'}
        type={'button'}
      />
    </div>
  );
};

export default CreateAppointment;
