import './privacyPage.scss';
import { useNavigate } from 'react-router-dom';
import PrivacySidebar from 'components/privacy/PrivacySidebar';

import {
  Introduction,
  About,
  HowCollectsInformation,
  WhenCollectsWhatCollect,
  WhyCollectWhatWeUseFor,
  DisclosureOfPersonalInformation,
  TreatmentAndStorageOfInformation,
  RetentionOfInformation,
  ManagingPersonalInformation,
} from 'components/privacy/privacyPolicySection';

const PrivacyPage = () => {
  const navigate = useNavigate();

  const handleGoback = () => {
    navigate('/');
  };

  return (
    <>
      <div className="page-container">
        <div className="sidebar">
          <PrivacySidebar />
        </div>

        <div className="main-content-container">
          <button onClick={handleGoback} className="back-button">
            Go back
          </button>

          <Introduction />
          <About />
          <HowCollectsInformation />
          <WhenCollectsWhatCollect />
          <WhyCollectWhatWeUseFor />
          <DisclosureOfPersonalInformation />
          <TreatmentAndStorageOfInformation />
          <RetentionOfInformation />
          <ManagingPersonalInformation />
        </div>
      </div>
    </>
  );
};

export default PrivacyPage;
