import Button from './Button';
import Input from './FormInput';
import './AccountVerificationForm.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import '../global-styles.scss';
import { verifyKyc } from '../redux/actions/authAction';

const AccountVerificationForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    // Define your validation rules for each input field
    registrationNumber: Yup.string().required(),
    postalCode: Yup.string().required(),
    suburb: Yup.string().required(),
  });
  const { email } = useSelector((state) => state.auth.user);

  const formik = useFormik({
    initialValues: {
      postalCode: '',
      suburb: '',
      registrationNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      try {
        setLoading(true);
        const kycStatus = await dispatch(verifyKyc({ ...values, email }));

        setLoading(false);
        setErrorMessage(null);
        if (kycStatus.toLowerCase() === 'pending') navigate('/kyc-pending');
      } catch (error) {
        setLoading(false);
        const errorMessage =
          error?.response?.data?.message ||
          'There was an error. Please try again later';
        setErrorMessage(errorMessage);
      }
    },
  });
  return (
    <form
      className="account-verification-form-container"
      onSubmit={formik.handleSubmit}
    >
      <div className="account-verification-form-inner-conatiner">
        <div className="account-verification-form-header">
          <h3>Provider Verification</h3>
          <p>
            confirm your identity <br></br>
          </p>
        </div>
        {error && (
          <div className="form-action-message">
            <p>{error}</p>
          </div>
        )}
        <div className="account-verification-form-input-container">
          <div className="input-column">
            <Input
              label={'Registration Number'}
              type="text"
              name="registrationNumber"
              value={formik.values.registrationNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.registrationNumber}
            />
          </div>
          <div className="input-column">
            <Input
              label={'Postal Code'}
              type="text"
              name="postalCode"
              value={formik.values.postalCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.postalCode}
            />
          </div>
          <div className="input-column">
            <Input
              label={'Suburb'}
              type="text"
              name="suburb"
              value={formik.values.suburb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              hasError={!!formik.errors.suburb}
            />
          </div>

          <div className="account-verification-form-button-container">
            <Button
              type="submit"
              disabled={!formik.isValid}
              isLoading={loading}
              label="Submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountVerificationForm;
