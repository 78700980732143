export function getGreeting() {
  const userTimezoneOffset = new Date().getTimezoneOffset() / 60;
  const userHour = (new Date().getUTCHours() + userTimezoneOffset + 24) % 24;

  if (userHour >= 5 && userHour < 12) {
    return 'Good morning';
  } else if (userHour >= 12 && userHour < 17) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}
