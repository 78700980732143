import React from 'react';

export const Introduction = () => {
  return (
    <div id="intro">
      <h1>Care Plan 365 Privacy Policy</h1>
      <p>
        This policy (‘<strong>Privacy Policy</strong>’) explains how{' '}
        <strong>
          Care Plan 365 Pty Ltd (ACN 647 531 299) (‘Care Plan 365’)
        </strong>{' '}
        seeks to protect the Personal Information of individuals. Care Plan 365
        is committed to protecting the safety and security of the personal
        information of individuals whose information Care Plan 365 has access
        to, including health service providers and professionals, their
        patients, and other persons with whom Care Plan 365 interacts (each a ‘
        <strong>User</strong>’ or ‘<strong>you</strong>’).
      </p>
      <p>
        The Privacy Policy has been developed in accordance with the Privacy Act
        1988 (Cth) (‘<strong>Act</strong>’) and other legislation or regulations
        that apply to Care Plan 365 in certain circumstances, including without
        limitation the Health Records Act 2001 (Vic) (‘
        <strong>Health Records Act</strong>’).
      </p>
      <p>
        Under the Act, <strong>“Personal Information”</strong> is defined as:
        “Information or an opinion about an identified individual, or an
        individual who is reasonably identifiable:
        <br />
        (a) whether the information or opinion is true or not; and
        <br />
        (b) whether the information or opinion is recorded in a material form or
        not.”
      </p>
      <p>
        <strong>
          Please read this Privacy Policy carefully in order to understand how
          your Personal Information is collected, held, used, or otherwise
          processed by us.
        </strong>
      </p>
      <p>
        Care Plan 365 reserves the right to make changes or updates to this
        Privacy Policy from time to time. If this happens, we will update this
        Privacy Policy and notify you of any changes, most likely via email.
        However, you should also periodically check this Privacy Policy for any
        updates.
      </p>
    </div>
  );
};
export const About = () => {
  return (
    <div id="about">
      <h1>1. About Care Plan 365</h1>
      <p>
        Care Plan 365 is the creator and provider of an integrated patient
        management platform that facilitates efficient cooperation and
        communication between health care providers and professionals caring for
        the same patients. Care Plan 365 provides its services through a
        web-based platform that is accessible at{' '}
        <a href="https://cp365.health">https://cp365.health/</a> (‘Platform’).
      </p>
      <p>
        In providing the Care Plan 365 Platform, we are sensitive to Users’
        concerns about the safety of their Personal Information. In essence,
        Care Plan 365 will typically only:
        <br />
        collect, use or share your Personal Information with your consent
        (unless it is not reasonable in the circumstances to obtain your consent
        and it is legally permissible for us to do so) or when required by a
        legal obligation; and
        <br />
        interact with your Personal Information in order to: (a) provide Users
        with the Care Plan 365 Platform and (b) help us improve and develop the
        Care Plan 365 Platform.
      </p>
      <p>
        Care Plan 365 has developed our privacy framework to assist Users, and
        to comply with privacy legislation and regulations applicable to us and
        our management of your Personal Information.
      </p>
      <br />
      <h3>HOW CARE PLAN 365 COLLECTS YOUR PERSONAL INFORMATION</h3>
      <p>
        Care Plan 365 collects Personal Information from individuals in one of
        three main ways: Directly from Users, when they interact with Care Plan
        365 Platform (e.g. if a User submits information during the creation of
        their account);
        <br />
        Passively from Users, when they interact with our website, online
        platforms and digital presence;
        <br />
        From third-parties in certain, specific circumstances (e.g. if a health
        service provider or professional updates information about a patient).
        The specifics of Personal Information collected in each situation is
        discussed further below.
      </p>
    </div>
  );
};

export const HowCollectsInformation = () => {
  return (
    <div id="how_collects">
      <h1>2. How Care Plan 365 Collects your Personal Information.</h1>
      <p>
        Care Plan 365 collects Personal Information from individuals in one of
        three main ways:
      </p>
      <p>
        (a)
        <strong>
          Directly from Users, when they interact with Care Plan 365 Platform
          (e.g. if a User submits information during the creation of their
          account);
        </strong>
        <br />
        (b)
        <strong>
          Passively from Users, when they interact with our website, online
          platforms and digital presence;
        </strong>
        <br />
        (c)
        <strong>
          From third-parties in certain, specific circumstances (e.g. if a
          health service provider or professional updates information about a
          patient).
        </strong>
      </p>
      <p>
        The specifics of Personal Information collected in each situation are
        discussed further below.
      </p>
    </div>
  );
};

export const WhenCollectsWhatCollect = () => {
  return (
    <div id="when_collects_what_collect">
      <h1>
        3. WHEN CARE PLAN 365 COLLECTS INFORMATION FROM USERS AND WHAT WE
        COLLECT
      </h1>
      <p>
        Care Plan 365 collects Personal Information from individuals in one of
        three main ways:
      </p>
      <p>
        (a)
        <strong>Personal Information collected directly</strong>
        <br />
        When a User makes an enquiry or sends us an expression of interest on
        our website, Platform, or other points of contact, we may collect the
        following types of Personal Information directly and consensually:
      </p>
      <ul>
        <li>
          Basic contact information, including your name, email, and phone
          number; and
        </li>
        <li>
          Enquiry information, such as an indication of when you would be
          interested in using the Platform, or other information provided by you
          regarding your enquiry.
        </li>
      </ul>
      <br />
      When Users register an account on the Platform, we may collect the
      following types of Personal Information directly and consensually from
      Users:
      <br />
      <ul>
        <li>
          If you are a health service provider or professional:
          <ul>
            <li>
              Your professional identifiers (e.g., Medicare provider number,
              AHPRA Registration number); or
            </li>
            <li>
              Details of any specialist practice, qualifications, and industry
              body memberships (as requested by us, if applicable);
            </li>
          </ul>
        </li>

        <li>
          If you are a patient, any:
          <ul>
            <li>
              Information needed to match your personal governmental records
              (e.g., your full name, your address, your date of birth, your sex
              at birth, your Medicare number (including card reference number,
              or a photograph uploaded by yourself); and
            </li>
            <li>
              Any relevant health information submitted by you in respect of
              your treatment by your health care providers or professionals to;
              and
            </li>
          </ul>
        </li>
      </ul>
      <br />
      <p>
        When you respond to a survey we may directly and consensually collect
        the Personal Information disclaimed and explained on the survey form.
        When you provide Care Plan 365 with unsolicited feedback or otherwise
        interact with Care Plan 365 on your own accord we may collect any
        contact information you provide (including Personal Information), as
        well as your feedback. When you make an application for employment at
        Care Plan 365, we may collect any Personal Information provided within
        that application, such as the contents of a personal statement made in
        support of your application.
      </p>
      <br />
      <p>
        (b)
        <strong>Personal Information collected passively</strong>
        <br />
        As you use the online and digital components of the Platform (e.g.,
        accessing our website, logging into your account on the Platform, or
        interacting with our advertisements) or our broader services we may
        collect the following types of Personal Information about your usage:
      </p>
      <ul>
        <li>
          Content that you post and submit, including posts on our social media
          accounts or on the Platform, as well as similar content that is posted
          about you by others;
        </li>
        <li>
          The following types of browser, system and device information
          regarding Care Plan 365’ and other devices you use to access our
          digital content:
          <br />
          <ul>
            <li>
              Locational information, such as in the form of the IP address from
              which you access the Care Plan 365 Platform;
            </li>
            <li>
              Web data tracking information, such as data from cookies stored on
              your device, including cookie IDs and settings, as well as logs of
              your usage of the Care Plan 365 Platform;
            </li>
          </ul>
        </li>
        <li>
          System usage information, including logs of your access to the
          Platform.
        </li>
      </ul>
      <br />
      <p>
        (c)
        <strong>Personal Information collected from third-parties</strong>
        <br />
        In certain specific situations, Care Plan 365 will collect Personal
        Information about you from third-parties. The types of Personal
        Information collected include:
      </p>
      <ul>
        <li>
          Health information provided about patients by their, approved health
          service providers or professionals (e.g., patient records that are
          transferred through the platform); and
        </li>
        <li>
          Web data tracking information that fit certain parameters of who we
          think could become Care Plan 365 Users (e.g., heat maps developed
          through Google Analytics which track patterns of user interactions
          with our web pages).
        </li>
      </ul>
    </div>
  );
};

export const WhyCollectWhatWeUseFor = () => {
  return (
    <div id="why_collect_what_we_use_for">
      <h1>
        4. WHY CARE PLAN 365 COLLECTS YOUR PERSONAL INFORMATION AND WHAT WE USE
        IT FOR
      </h1>
      <p>
        Although Care Plan 365 collects Personal Information from Users in a
        number of circumstances, Care Plan 365 will only collect this
        information to provide and develop the Care Plan 365 Platform and
        associated services. Here are the main ways we use Personal Information
        to achieve these objectives:
      </p>
      <p>
        <u>Communicating with Users</u>
        <br />
        Care Plan 365 will use basic contact and enquiry information to
        communicate with individuals about their enquiries, interest in events,
        and for other administrative purposes related to the specific reason for
        which the Personal Information was collected.
        <br />
        If Users have consented, Care Plan 365 will also use these types of
        Personal Information to share relevant news and updates about Care Plan
        365 and the Care Plan 365 Platform.
        <br />
      </p>
      <p>
        <u>Administration and delivery of the Platform</u>
        <br />
        Care Plan 365 will use basic contact and account registration
        information to engage with Users for administrative purposes (e.g.,
        resetting account password) and to effectively and efficiently provide
        them with the Care Plan 365 Platform and associated services (e.g., to
        set and receive assignments required under program syllabuses). If you
        are a User who is a health service provider or professional, Care Plan
        365 may use your professional identifiers to verify your identity, place
        of work, qualifications, and professional registrations and your medical
        provider number. If you are a User who is a patient, Care Plan 365 may
        use your governmental record information to verify your identity and
        allow you and your approved health care providers and professionals to
        access the Platform. Health information made available by, or for use
        by, health care providers and professionals may be processed on the
        Platform, but Care Plan 365 will not use any such personal information.
        Any use of such health information will only be by health care providers
        and professionals in their treatment of patients, and subject to terms
        and conditions governing the use of the Platform.
        <br />
      </p>
      <p>
        <u>Research and development</u>
        <br />
        Care Plan 365 will use survey information to develop, test, and improve
        the Care Plan 365 Platform and associated services. Care Plan 365’s
        preference will be to de-identify this information first.
      </p>
      <p>
        <u>Marketing</u>
        <br />
        Where Users have consented, Care Plan 365 will use basic contact and
        enquiry to provide Users with relevant marketing materials and offers.
        Users can always opt out of this through the functionality provided in
        each marketing communication (e.g., by clicking “unsubscribe” at the
        bottom of an email). Care Plan 365 will also use passively collected web
        data tracking information to display marketing materials and offers on
        third-party websites (e.g., if you have visited an Care Plan 365 program
        page, we may use cookies to later display banner advertisements to Users
        on LinkedIn). Users can stop or manage web data tracking information
        through their browser preferences.
      </p>
    </div>
  );
};

export const DisclosureOfPersonalInformation = () => {
  return (
    <div id="disclosure_of_personal_info">
      <h1>5. CARE PLAN 365 DISCLOSURE OF PERSONAL INFORMATION</h1>
      <p>
        Generally, Care Plan 365 does not disclose Personal Information to any
        third-parties except:
      </p>
      <br />
      <ul>
        <li>
          To health care providers or professionals in accordance with a
          patient’s express consent;
        </li>
        <li>
          Service providers Care Plan 365 engages to help us provide and develop
          the Care Plan 365 Platform (e.g. cloud service providers or
          consultants);
        </li>
        <li>
          In some specific circumstances, Users’ employers (e.g. the companies
          they work for); and
        </li>
        <li>
          Law enforcement agencies, or another party that has a legitimate legal
          right to access the information.
        </li>
      </ul>
      <br />
      <p>
        The above disclosures will only be made in circumstances where the
        recipient has provided an undertaking that they will maintain the
        confidentiality of the information and that they recognize the
        appropriate limitations placed on the use of the information.
        Disclosures will also always be in accordance with this Privacy Policy.
        In the case of Users’ organizations, Care Plan 365 will seek the
        explicit consent of the User before disclosing their information.
      </p>
      <p>
        <u>Overseas Disclosure</u>
        <br />
        Care Plan 365 does not currently disclose any Personal Information to
        overseas entities or third parties.
        <br />
        If this changes in the future, we will endeavor to ensure overseas
        disclosures are always made once Care Plan 365 has taken all reasonable
        steps to determine the information will be treated as at least as
        favorably under the Act and other applicable privacy laws.
        <br />
      </p>
    </div>
  );
};

export const TreatmentAndStorageOfInformation = () => {
  return (
    <div id="treatment_and_storage_of_information">
      <h1>6. CARE PLAN 365 TREATMENT AND STORAGE OF INFORMATION</h1>
      <p>
        <u>Care Plan 365 general approach</u>
        Care Plan 365 will keep your Personal Information confidential and not
        sell or knowingly divulge User information to any external
        third-parties, unless:
      </p>
      <br />
      <ul>
        <li>
          We believe, in good faith, that we are required to share the Personal
          Information with a third party to comply with legitimate legal
          obligations;
        </li>
        <li>
          The disclosure is to a third-party processor of Personal Information
          that acts on our behalf and/or under our instruction to enable us to
          deliver the Care Plan 365 Platform (e.g. a cloud service
          provider);and/or
        </li>
        <li>
          To protect the safety of Users and the security of our Care Plan 365
          Platform
        </li>
      </ul>
      <br />
      <p>
        Care Plan 365 seeks the informed and voluntary consent of individuals
        whenever it collects their information, or as soon as possible after.
      </p>
      <p>
        Users can always refuse or revoke this consent, but sometimes this will
        affect Care Plan 365's ability to provide them with the Care Plan 365
        Platform and associated services. Care Plan 365 will advise Users if
        this is the case.
      </p>
      <p>
        <u>De-identification</u>
        <br />
        Care Plan 365 will keep your Personal Information confidential and not
        sell or knowingly divulge User information to any external
        third-parties, unless:
        <br />
        De-identified information refers to information that cannot reasonably
        be used to identify a particular individual.
        <br />
        De-identified information that will never be able to personally identify
        particular individuals is referred to as anonymized information (e.g.
        statistics that show 90% of Users were happy with the Care Plan 365
        Platform). Additionally, de-identified information that can identify
        individuals only if it is combined with another, separate piece of
        information is referred to as pseudonymized information (e.g.
        governmental ID numbers in isolation).
        <br />
        Where possible Care Plan 365 will aim to collect, store, and use
        anonymized information as a first preference, and if not, then
        pseudonymized information.
        <br />
        However, sometimes it will be impractical for User information to be
        de-identified or treated in this way, and in this case, Care Plan 365
        will continue to use and hold the information in a personally
        identifiable state. For example, if Care Plan 365 needs to reply to a
        User inquiry, we will have to use the contact information provided.
      </p>
      <p>
        <u>Security</u>
        <br />
        Care Plan 365 is committed to information security. We will use all
        reasonable endeavors to keep the Personal Information we collect, hold,
        and use in a secure environment.
        <br />
        When information collected or used by Care Plan 365 is stored on
        third-party service providers (e.g. AWS cloud servers) and transmitted
        using encrypted means. Care Plan 365 takes reasonable steps to ensure
        these third-parties use industry-standard security.
        <br />
        As part of our privacy framework, we endeavor to routinely review these
        security procedures and consider the appropriateness of new technologies
        and methods.
      </p>
      <p>
        <u>Data Breaches</u>
        <br />
        In the circumstances where Care Plan 365 suffers a data breach that
        contains Personal Information, we will endeavor to take all necessary
        steps to comply with the Notifiable Data Breach Scheme outlined under
        the Act.
        <br />
        This means we will immediately make an objective assessment of whether a
        breach of Personal Information is likely to result in serious harm to
        individuals, and if this is the case, endeavor to notify the affected
        individual(s) and the Australian Information Commissioner.
        <br />
      </p>
    </div>
  );
};

export const RetentionOfInformation = () => {
  return (
    <div id="retention_of_information">
      <h1>7. CARE PLAN 365 RETENTION OF INFORMATION</h1>
      <p>
        Care Plan 365 retains Personal Information until it is no longer needed
        to provide or develop the Care Plan 365 Platform and associated
        services, or until the individual who the Personal Information concerns
        asks us to delete it, whichever comes first. It may take up to 30 days
        to delete Personal Information from our systems following a valid
        request for deletion.
      </p>
      <br />
      <p>However, Care Plan 365 will retain:</p>
      <br />
      <ul>
        <li>
          Personal Information in circumstances where we have legal and
          regulatory obligations to do so (e.g. for law enforcement purposes,
          employment law, corporate or tax record-keeping, and where the
          information is relevant to legitimate legal proceedings, or in keeping
          with its requirements under other Australian record-keeping
          legislation such as the Public Records Act 1973 (Vic)); and
        </li>
        <li>
          Anonymized information for analytic and service development purposes.
        </li>
      </ul>
      <br />
      <p>
        The information we retain will be handled in accordance with this
        Privacy Policy.
      </p>
    </div>
  );
};

export const ManagingPersonalInformation = () => {
  return (
    <div id="managing_your_personal_information">
      <h1>8. MANAGING YOUR PERSONAL INFORMATION</h1>
      <p>
        <u>Accessing and ensuring the accuracy of Personal Information</u>
        <br />
        Care Plan 365 takes reasonable steps to ensure that the Personal
        Information we collect and hold is accurate, up to date, and complete.
        <br />
        Users have the right to access and request the correction of any
        Personal Information we hold about them at any time. Any such requests
        should be made by directly contacting us at the details set out below.
        Care Plan 365 will grant access to the extent required or authorized by
        the Act and applicable laws and will take all reasonable steps to
        correct the relevant Personal Information where appropriate.
        <br />
        There may be circumstances in which Care Plan 365 cannot provide Users
        with access to information. We will advise you of these reasons if this
        is the case.
      </p>
      <p>
        <u>Contacting Care Plan 365</u>
        <br />
        Care Plan 365 has appointed a Privacy Officer to be the first point of
        contact for all privacy-related matters and to assist in ensuring our
        compliance with our privacy obligations.
      </p>
      <p>
        <u>Privacy Officer</u>
        <br />
        Privacy Officer akuh@cp365.health
        <br />
        1/53 Elgin Street
        <br />
        Berwick VIC 3806
        <br />
        ACN: 647 531 299
        <br />
        <br />
        If you have any queries or wish to make a complaint about a breach of
        this policy, the Act, or the Health Records Act, you can contact or
        lodge a complaint with our Privacy Officer using the contact details
        above. You will need to provide sufficient details regarding your
        complaint as well as any supporting evidence and/or information.
        <br />
        The Privacy Officer will respond to your query or complaint as quickly
        as possible. Care Plan 365 will contact you if we require any additional
        information from you and will notify you in writing (which includes
        electronic communication via email) of the relevant determination. If
        you are not satisfied with the determination, you can contact us to
        discuss your concerns or complain to the Australian Privacy Commissioner
        via www.oaic.gov.au.
        <br />
        This Privacy Policy was last updated on 24 November 2021.
      </p>
    </div>
  );
};
