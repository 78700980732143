import React from 'react';
import styles from './AsrsResponse.module.scss';

const Responsedivs = ({ question, answer }) => {
  console.log({
    question,
    answer,
  });
  return (
    <div className={styles.optionsContainer}>
      {question.responses.map((response, index) => (
        <div
          key={index}
          className={`${styles.optiondiv} ${
            response === answer ? styles.highlight : ''
          }`}
        >
          {response}
        </div>
      ))}
    </div>
  );
};

export default Responsedivs;
