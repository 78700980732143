import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import addIcon from '../../../assets/logo/add.svg';
import teamMember3 from '../../../assets/logo/userAvatar.svg';
import notesIcon from '../../../assets/logo/notes.svg';
import { api } from 'api';
import styles from './detailsCareTeam.module.scss';
import Modal from '../../../components/Modal/Modal';
import ModalInput from '../../../components/Modal/ModalInput/ModalInput';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'components/Button';

const DetailsCareTeam = ({ careTeam, patientNotes }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.carePlanSummary) || {};
  const patientId = user?.id;

  const notes = patientNotes
    ?.map((data) => ({
      ...data,
      date: data?.patientNoteDate,
      note: data?.patientNote,
      id: data?._id || data?.id,
      practitioner: data?.practitioner,
    }))
    .filter((data) => data?.note && data.status === 'COMPLETED');

  const handleInvite = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email.toLowerCase())) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      setLoading(true);
      const formData = {
        email: email.toLowerCase(),
        patientId,
      };
      const response = await api.post(
        '/practitioners/invite-practitioner',
        formData
      );

      setEmail('');
      toast.success('Invite sent successfully');
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to send invite');
      }

      setLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.header}>
          <h1>Care Team</h1>
          <div
            onClick={() => setOpenAddModal(true)}
            role="button"
            className={styles.addButton}
          >
            <img src={addIcon} alt="" />
            <h2>Add to team</h2>
          </div>
        </div>
        <div className={styles.teamMembers}>
          {careTeam.slice(0, 5).map((data, index) => (
            <div className={styles.member} key={index}>
              <div className={styles.memberImage}>
                <img src={data?.imageUrl || teamMember3} alt="" />
                <div>
                  <h3>{`${data?.firstName} ${data?.lastName}`}</h3>
                  <span>{data?.type}</span>
                </div>
              </div>
              {/* <span role="button" className={styles.viewButton}>
                View Details
              </span> */}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.left}>
        <div className={styles.header}>
          <h1>Patient Notes</h1>
        </div>
        <div className={styles.notes}>
          {notes.map((data, index) => (
            <div className={styles.note} key={index}>
              <div className={styles.noteDate}>
                <img src={notesIcon} alt="" />
                <div>
                  {data.date && <h4>{moment(data.date).format('LL')}</h4>}
                  <span>
                    {data?.practitioner?.firstName +
                      ' ' +
                      data?.practitioner?.lastName}
                  </span>
                </div>
              </div>
              <span
                className={styles.viewNote}
                role="button"
                onClick={() =>
                  navigate(`/dashboard/user-patient-note/${data.id}`)
                }
              >
                View
              </span>
            </div>
          ))}
        </div>
      </div>
      <Modal
        isOpen={openAddModal}
        onClose={() => setOpenAddModal(false)}
        children={
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <h1>Add to Care Team</h1>
              <span>
                Provide email address of the practitioner to send an invite.
              </span>
            </div>
            <form>
              <ModalInput
                className={styles.modalInput}
                placeholder="Enter email"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                onClick={handleInvite}
                label="Send Invite"
                isLoading={loading}
              />
            </form>
          </div>
        }
      />
    </div>
  );
};

export default DetailsCareTeam;
