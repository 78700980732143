import React from 'react';
import checkIcon from '../../../assets/icons/orange-check.svg';

const CircleBar = ({ children, className, active, name }) => {
  return (
    <div className={className}>
      <div>{children > active ? children : <img src={checkIcon} alt="" />}</div>
      <p>{name}</p>
    </div>
  );
};

export default CircleBar;
