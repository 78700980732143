import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  billingOption: '',
  selectedItem: {
    itemNumber: '',
    scheduleFee: '',
  },
  billingDone: false,
};

const billingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    setBillingOption: (state, action) => {
      state.billingOption = action.payload;
      state.billingDone = false;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setBillingDone: (state, action) => {
      state.billingDone = action.payload;
    },
    resetBilling: (state) => {
      state = initialState;
    },
  },
});

export const {
  setBillingOption,
  setSelectedItem,
  setBillingDone,
  resetBilling,
} = billingsSlice.actions;
export default billingsSlice.reducer;
