import React from 'react';
import styles from './createCarePlan.module.scss';
import CarePlanNavigation from '../../components/CreateCarePlan/CarePlanNav/CarePlanNav';

const CreateCarePlan = () => {
  return (
    <div className={styles.root}>
      <CarePlanNavigation />
    </div>
  );
};

export default CreateCarePlan;
