import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextPlanStep } from '../../../redux/slices/carePlanSlice';
import GoBackButton from '../../GoBackButton/GoBackButton';

import avatar from '../../../assets/logo/userAvatar.svg';
import styles from './createPlan.module.scss';
import Modal from '../../Modal/Modal';
import ShortTermGoal from './shortTermGoal/ShortTermGoal';
import LongTermGoal from './longTermGoal/LongTermGoal';
import CreateMedication from './createMedication/CreateMedication';
import CreateTherapy from './createTherapy/CreateTherapy';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from 'api';
import Button from 'components/Button';
import { updateCarePlan } from 'redux/actions/carePlanAction';
import { toast } from 'react-toastify';
import { setCurrentCarePlan } from 'redux/slices/currentCarePlan';

const PlanGoalCard = ({ onClickEdit, header, content }) => (
  <div className={styles.planGoalCard}>
    <div className={styles.goalHeader}>
      <h1>{header}</h1>
      <button onClick={onClickEdit}>Edit</button>
    </div>
    <div className={styles.goalContent}>
      <span>{content}</span>
    </div>
  </div>
);

const PlanTherapyCard = ({ onClickFn, header, content }) => (
  <div className={styles.planGoalCard}>
    <div className={styles.goalHeader}>
      <h1>{header}</h1>
      <button onClick={onClickFn}>Edit</button>
    </div>
    <div className={styles.goalContent}>{content}</div>
  </div>
);

const CreatePlan = () => {
  const currentCarePlan = useSelector((state) => state.currentCarePlan) || {};
  const { assessment, medication, therapy, _id, patient } = currentCarePlan;
  const [openShortTermModal, setOpenShortModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLongTermModal, setOpenLongTermModal] = useState(false);
  const [openMedicationModal, setOpenMedicationModal] = useState(false);
  const [openTherapyModal, setOpenTherapyModal] = useState(false);
  const [reductionLevel, setReductionLevel] = useState(
    assessment?.shortTermGoal?.reductionLevel || ''
  );
  const [period, setPeriod] = useState(assessment?.shortTermGoal?.period || '');
  const [longTermGoal, setLongTermGoal] = useState(
    assessment?.longTermGoal || ''
  );

  const { data } = useSWR(['/files/medication'], fetcher);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const saveAndContinue = async () => {
    try {
      setLoading(true);
      const payload = {
        assessment,
        careplan: {
          ...currentCarePlan,
          therapy: currentCarePlan?.therapy,
          medication: currentCarePlan?.medication,
          assessment: currentCarePlan?._id,
          riskToSelf: currentCarePlan?.riskToSelf,
          riskToOthers: currentCarePlan?.riskToOthers,
          stepsCompleted: {
            ...currentCarePlan.stepsCompleted,
            stepTwoCompleted: true,
          },
        },
        userId: patient._id,
      };

      const { data } = await updateCarePlan(_id, payload);
      dispatch(
        setCurrentCarePlan({
          ...data.data,
        })
      );
      dispatch(handleNextPlanStep({ step: 1 }));
      navigate('/create-care-plan/schedule');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(handleNextPlanStep({ step: 1 }));
  });
  const handleBack = () => {
    navigate('/create-care-plan');
  };

  return (
    <div className={styles.rootPlan}>
      <section className={styles.topName}>
        <div className={styles.image}>
          <img src={avatar} alt="" />
          <div>
            <h1>{patient?.firstName + ' ' + patient?.lastName}</h1>
            <span>Care Plan</span>
          </div>
        </div>
        <GoBackButton onClick={handleBack} to={-1} />
      </section>
      <section className={styles.plan}>
        <div className={styles.planHeader}>
          <h1>Plan</h1>
          <span>
            Review long and short term goal with patient, and prescribe
            interventions
          </span>
        </div>
        <div className={styles.planCards}>
          <PlanGoalCard
            content={
              assessment?.shortTermGoal?.message || 'No goal specified yet.'
            }
            header="Short term goal"
            onClickEdit={() => setOpenShortModal(true)}
          />
          <PlanGoalCard
            content={assessment?.longTermGoal || 'No goal specified yet.'}
            header="Long term goal"
            onClickEdit={() => setOpenLongTermModal(true)}
          />
          <PlanTherapyCard
            btnLabel="Add Medication"
            header="Medication"
            onClickFn={() => setOpenMedicationModal(true)}
            onClickEdit={() => setOpenMedicationModal(true)}
            content={
              <div className={styles.medListWrapper}>
                {medication?.length === 0 ? (
                  <p style={{ textAlign: 'center' }}>No medications yet.</p>
                ) : (
                  medication?.map((med) => (
                    <div className={styles.medList}>
                      {med?.drugName} - {med?.dosage}
                    </div>
                  ))
                )}
              </div>
            }
          />
          <PlanTherapyCard
            btnLabel="Add Therapy"
            header="Therapy"
            content={
              <div className={styles.medListWrapper}>
                {!therapy?.therapyType ? (
                  <p style={{ textAlign: 'center' }}>No therapy advised yet.</p>
                ) : (
                  <div className={styles.therapyContent}>
                    <div className={styles.therapyHeader}>
                      <h1>Therapy type</h1>
                      <span>
                        {therapy?.therapyType === 'Others (Specify)'
                          ? therapy?.otherInterventions
                          : therapy?.therapyType}
                      </span>
                    </div>
                    <div className={styles.therapyHeader}>
                      <h1>Has safety planning been discussed?</h1>
                      <span>{therapy?.hasSafetyPlanning ? 'Yes' : 'No'}</span>
                    </div>
                  </div>
                )}
              </div>
            }
            onClickFn={() => setOpenTherapyModal(true)}
          />
        </div>
      </section>
      <div className={styles.saveAndContinueBtnContainer}>
        <Button
          label={'Continue'}
          type={'button'}
          onClick={saveAndContinue}
          isLoading={loading}
        />
      </div>

      <Modal
        isOpen={openShortTermModal}
        onClose={() => setOpenShortModal(false)}
        children={
          <ShortTermGoal
            assessment={assessment}
            closeModal={() => setOpenShortModal(false)}
            period={period}
            reductionLevel={reductionLevel}
            setPeriod={setPeriod}
            setReductionLevel={setReductionLevel}
          />
        }
      />
      <Modal
        isOpen={openLongTermModal}
        onClose={() => setOpenLongTermModal(false)}
        children={
          <LongTermGoal
            assessment={assessment}
            setLongTermGoal={setLongTermGoal}
            longTermGoal={longTermGoal}
            closeModal={() => setOpenLongTermModal(false)}
          />
        }
      />
      <Modal
        isOpen={openMedicationModal}
        onClose={() => setOpenMedicationModal(false)}
        children={
          <CreateMedication
            medicationList={data || []}
            closeModal={() => setOpenMedicationModal(false)}
          />
        }
      />
      <Modal
        isOpen={openTherapyModal}
        onClose={() => setOpenTherapyModal(false)}
        children={
          <CreateTherapy
            closeModal={() => setOpenTherapyModal(false)}
            therapy={therapy}
          />
        }
      />
    </div>
  );
};

export default CreatePlan;
