import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  user: {
    createdAt: null,
    dateOfApproval: null,
    email: null,
    firstName: null,
    imageUrl: null,
    isBankAccountConnected: false,
    isOtpVerified: false,
    kycStatus: null,
    lastLoggedIn: null,
    lastName: null,
    multiFactorAuth: true,
    phone: null,
    postalCode: null,
    practitionerLocations: [],
    referralCode: null,
    stripeAccountId: null,
    timezoneOffset: null,
    type: null,
    updatedAt: null,
    _id: null,
    businessInfo: {
      bio: null,
      businessEmail: null,
      workPhoneNumber: null,
      postalCode: null,
      suburb: null,
    },
    areasOfInterest: [],
    languages: [],
    education: [],
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const { token, user } = action.payload;
      state.isLoggedIn = true;
      state.accessToken = token;
      state.user = user;
    },
    signUpSuccess: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.accessToken = token;
    },
    kycVerification: (state, action) => {
      const { kycStatus } = action.payload;
      state.user.kycStatus = kycStatus;
    },

    logout: (state) => {
      state.email = '';
      state.password = '';
      state.isLoggedIn = false;
      state.accessToken = null;
    },
    addEducation: (state, action) => {
      state.user.education.push(action.payload);
    },
    removeEducation: (state, action) => {
      const { index } = action.payload;
      state.user.education.splice(index, 1);
    },
    updateEducation: (state, action) => {
      const { index, education } = action.payload;
      state.user.education[index] = education;
    },
  },
});

export const {
  loginSuccess,
  logout,
  signUpSuccess,
  kycVerification,
  addEducation,
  removeEducation,
  updateEducation,
} = authSlice.actions;

export default authSlice.reducer;
