import './TermsPage.scss';
import { useNavigate } from 'react-router-dom';
import TermSidebar from 'components/terms/termSidebar';

import {
  AboutTheseTerms,
  ChargesAndFees,
  DefinitionsSection,
  DisputeResolution,
  GeneralSection,
  Indemnity,
  IntellectualProperty,
  Liability,
  LinkingToPlatform,
  Obligations,
  OverviewOfServices,
  PaymentProcess,
  RefundPolicy,
  ReturnPolicy,
  PlatformTermsOfService,
  PrivacyAndConsent,
  PurposeOfPlatform,
  Registration,
  Termination,
  UnavoidableEvents,
  UsingPlatformAndServices,
  Warranties,
  WhoThisAppliesTo,
} from 'components/terms/termSection';

const TermsPage = () => {
  const navigate = useNavigate();

  const handleGoback = () => {
    navigate('/');
  };

  return (
    <>
      <div className="page-container">
        <div className="sidebar">
          <TermSidebar />
        </div>

        <div className="main-content-container">
          <button onClick={handleGoback} className="back-button">
            Go back
          </button>
          <PlatformTermsOfService />
          <WhoThisAppliesTo />
          <AboutTheseTerms />
          <PurposeOfPlatform />
          <OverviewOfServices />
          <UsingPlatformAndServices />
          <Registration />
          <PrivacyAndConsent />
          <ChargesAndFees />
          <PaymentProcess />
          <RefundPolicy />
          <ReturnPolicy />
          <Obligations />
          <Termination />
          <Warranties />
          <Liability />
          <Indemnity />
          <IntellectualProperty />
          <UnavoidableEvents />
          <DisputeResolution />
          <LinkingToPlatform />
          <GeneralSection />
          <DefinitionsSection />
        </div>
      </div>
    </>
  );
};

export default TermsPage;
