import React from 'react';
import styles from './billingMethodCard.module.scss';
import CustomRadio from '../../../CustomRadioButton/CustomRadioButton';

const BillingMethodCard = ({
  selectCard,
  setSelectCard,
  value,
  title,
  disabled,
  description,
}) => {
  return (
    <div
      onClick={disabled ? null : setSelectCard}
      className={`${styles.root} ${selectCard === value ? styles.active : ''} ${
        disabled ? styles.disabled : ''
      }`}
    >
      <div className={styles.head}>
        <h1>{title}</h1>
        <CustomRadio
          disabled={disabled}
          name="billing"
          checked={selectCard === value}
          value={value}
          onChange={setSelectCard}
        />
      </div>
      <p>{description}</p>
    </div>
  );
};

export default BillingMethodCard;
