import React from 'react';
import { useSelector } from 'react-redux';
import './locationDisplayCard.scss';

const LocationDisplayCard = () => {
  const { user } = useSelector((state) => state.auth) || {};

  const locations = user?.practitionerLocations || {};
  const providerNumber = user?.providerNumber || '';

  return (
    <div className="location_wrapper">
      <div className="tableContainer">
        <h2>Your Locations</h2>
        <p>These locations are areas where you will be servicing</p>

        <table>
          <thead>
            <tr className="tableHeader">
              <th>Location Name</th>
              <th>Location Id</th>
              <th>Provider Number</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location, index) => (
              <tr key={index} className="tableRow">
                <td>{location.name}</td>
                <td>{location.locationId}</td>
                <td>{providerNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LocationDisplayCard;
