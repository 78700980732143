import React from 'react';
import addIcon from '../../../../assets/logo/add.svg';

import styles from './request.module.scss';

const ScheduleRequest = ({ requests, setRequests, close }) => {
  const handleAddFields = () => {
    setRequests([...requests, '']);
  };

  const handleChangeValues = (i, e) => {
    const newFormValues = [...requests];
    newFormValues[i] = e.target.value;
    setRequests(newFormValues);
  };

  const handleDeleteRequest = (id) => {
    const filteredFormValue = requests.filter((item, index) => index !== id);
    setRequests(filteredFormValue);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Request and Orders</h1>
        <span>Add Additional requests</span>
      </div>
      <div>
        {requests.map((element, index) => (
          <div className={styles.request}>
            <div className={styles.requestHeader}>
              <h1>Request {index + 1}</h1>
              <span
                onClick={() => handleDeleteRequest(index)}
                className={styles.delete}
              >
                delete
              </span>
            </div>

            <div className={styles.inputField}>
              <textarea
                onChange={(e) => handleChangeValues(index, e)}
                placeholder="Write something"
                name="request"
                value={element}
                rows={10}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div onClick={handleAddFields} className={styles.add}>
          <img src={addIcon} alt="" />
          <span>Add Request</span>
        </div>
        <button onClick={close} className={styles.done}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ScheduleRequest;
