import React from 'react';
import styles from './securityDisplayCard.module.scss';

const SecurityDisplayCard = () => {
  return (
    <>
      <div className={styles.securityContainer}>
        <div className={styles.securityHeader}>
          <h2>Two-Factor Authentication</h2>
        </div>
        <div className={styles.securityDescription}>
          <p>
            Activate two factor authentication to secure your account. Upon
            activation, you will be required to provide a code sent to your
            mobile phone whenever you login.
          </p>
        </div>
        <div className={styles.securityActivate}>
          <div className={styles.svg}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="22"
              viewBox="0 0 40 22"
              fill="none"
            >
              <path
                d="M0.5 11C0.5 5.20101 5.20101 0.5 11 0.5H29C34.799 0.5 39.5 5.20101 39.5 11C39.5 16.799 34.799 21.5 29 21.5H11C5.20101 21.5 0.5 16.799 0.5 11Z"
                fill="#F2F2F2"
                stroke="#F2F2F2"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 19C16.4183 19 20 15.4183 20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11C4 15.4183 7.58172 19 12 19Z"
                fill="#BDBDBD"
              />
            </svg>
          </div>
          <div className={styles.activateDescripton}>
            <p>Activate two factor authentication </p>
          </div>
        </div>
        <div className={styles.changePassword}>
          <h5>Change Password</h5>
        </div>
        <div className={styles.securityInput}>
          <input type="input" placeholder="Password" />
          <input type="input" placeholder="Confirm Password" />
        </div>
        <div className={styles.securityButton}>
          <button>Save Changes</button>
        </div>
      </div>
    </>
  );
};

export default SecurityDisplayCard;
