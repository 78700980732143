import React from 'react';
import './dashboardUsersTable.scss';
import CustomTable from './CustomTable';
import searchIcon from '../../assets/icons/search.svg';
import { useState } from 'react';

const DashboardUsersTable = () => {
  const [searchInput, setSearchInput] = useState('');
  return (
    <div className="dashboard-users-table">
      <div className="dashboard-users-table-header">
        <div className="du-patient-list">
          <p>Patient List</p>
          <div className="searchContainer">
            <img src={searchIcon} alt="" />
            <input
              type="search"
              placeholder="Patient Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </div>
        <div className="du-view-all"></div>
      </div>
      <div>
        <CustomTable searchInput={searchInput} />
      </div>
    </div>
  );
};

export default DashboardUsersTable;
