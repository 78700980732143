import React, { useEffect, useState } from 'react';
import './customTable.scss';
import { useNavigate } from 'react-router-dom';
import defaultProfilePicture from '../../assets/logo/userAvatar.svg';
import { renderPaginationNumbers } from '../../utils/pagination';
import { fetchPatients } from '../../redux/actions/patientAction';
import { Skeleton } from 'antd';
import CurrentPatientsTable from '../Tables/CurrentPatients';
import PendingPatientsTable from '../Tables/PendingPatients';
import { fetcher } from 'api';
import useSWR from 'swr';

const CustomTable = ({ searchInput }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [patients, setPatients] = useState([]);
  const [totalDocs, setTotalDocs] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('CURRENT');
  const navigate = useNavigate();
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const params = {
    kycStatus: activeTab === 'CURRENT' ? 'approved' : 'pending',
    page: currentPage,
    limit: 10,
  };

  const { data, error, isLoading, isValidating } =
    useSWR(['/practitioners/patients', params], fetcher) || {};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const kycStatus = activeTab === 'CURRENT' ? 'approved' : 'pending';
      try {
        let params = {};
        if (searchInput) {
          params = { patientName: searchInput };
        }

        const response = await fetchPatients({
          ...params,
          kycStatus,
          page: currentPage,
          limit: 10,
        });
        setPatients(response.patients);
        setTotalPages(response.totalPages);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    // const debounceSearch = setTimeout(() => {
    //   fetchData();
    // }, 1000);

    // return () => clearTimeout(debounceSearch);
    fetchData();
  }, [currentPage, activeTab, searchInput]);

  console.log(patients, 'patients');

  const displayPageNumbers = () => {
    let pageNumbers = [];
    const maxPageNumbers = 8;

    for (
      let i = currentPage - 1;
      i > 0 && pageNumbers.length < maxPageNumbers / 2;
      i--
    ) {
      pageNumbers.unshift(i);
    }

    pageNumbers.push(currentPage);

    for (
      let i = currentPage + 1;
      i <= totalPages && pageNumbers.length < maxPageNumbers;
      i++
    ) {
      pageNumbers.push(i);
    }

    while (pageNumbers.length < maxPageNumbers && pageNumbers[0] > 1) {
      pageNumbers.unshift(pageNumbers[0] - 1);
    }

    while (
      pageNumbers.length < maxPageNumbers &&
      pageNumbers[pageNumbers.length - 1] < totalPages
    ) {
      pageNumbers.push(pageNumbers[pageNumbers.length - 1] + 1);
    }

    return pageNumbers;
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const displayPageNumber = (
    <div className="paginationContainer">
      <div className="pagination-button-container">
        <span
          className={`page-number ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={handlePrevPage}
        >
          Prev
        </span>

        {displayPageNumbers().map((pageNumber) => (
          <span
            key={pageNumber}
            className={`page-number ${
              pageNumber === currentPage ? 'active' : ''
            }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </span>
        ))}

        <span
          className={`next ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={handleNextPage}
        >
          Next
        </span>
      </div>
    </div>
  );

  return (
    <div className="custom-table">
      <div className="custom-table-top-tabs">
        <div className="custom-table-top-tabs-items">
          <span
            onClick={() => setActiveTab('CURRENT')}
            className={activeTab === 'CURRENT' ? 'tabs-active' : ''}
          >
            Current Patient
          </span>
          <span
            onClick={() => setActiveTab('INVITED')}
            className={activeTab === 'INVITED' ? 'tabs-active' : ''}
          >
            Invited Patient
          </span>
        </div>
      </div>

      {loading ? (
        Array.from({ length: 5 }, (_, index) => (
          <div className="skeleton" key={index}>
            <Skeleton key={index} paragraph={false} active />
          </div>
        ))
      ) : (
        <>
          {activeTab === 'CURRENT' ? (
            <CurrentPatientsTable
              defaultProfilePicture={defaultProfilePicture}
              navigate={navigate}
              data={patients || []}
            />
          ) : (
            <PendingPatientsTable
              defaultProfilePicture={defaultProfilePicture}
              navigate={navigate}
              data={patients || []}
            />
          )}
          <div>{displayPageNumber}</div>
        </>
      )}
    </div>
  );
};

export default CustomTable;
