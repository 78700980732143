import React from 'react';

import carePlan from '../../assets/logo/cp.svg';
import './privacy.module.scss';

const PrivacySidebar = () => {
  return (
    <>
      <div className="sidenav">
        <div className="sidebar-header">
          <img src={carePlan} alt="careplan logo" className="sidebar-logo" />
          <h3>Careplan 365 Privacy Policy</h3>
        </div>
        <ul>
          <li>
            <a href="#intro">Introduction</a>
          </li>
          <li>
            <a href="#about">About careplan 365</a>
          </li>
          <li>
            <a href="#how_collects">
              How Careplan 365 Collects Your Personal Information
            </a>
          </li>
          <li>
            <a href="#when_collects_what_collect">
              When Careplan 365 Collects Information from Users and What We
              Collect
            </a>
          </li>
          <li>
            <a href="#why_collect_what_we_use_for">
              Why Careplan 365 Collects Your Personal Information And Why What
              We Use It For
            </a>
          </li>
          <li>
            <a href="#disclosure_of_personal_info">
              Careplan 365 Disclosure of Personal Information
            </a>
          </li>

          <li>
            <a href="#treatment_and_storage_of_information">
              Careplan 365 Retention Of Information
            </a>
          </li>
          <li>
            <a href="#retention_of_information">
              Managing Personal Information Your Information
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrivacySidebar;
