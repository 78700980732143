import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const GeneralList = ({ listType, columns, data, baseRedirectUrl }) => {
  const navigate = useNavigate();

  return (
    <table style={{ padding: '10px 0px' }}>
      <thead>
        <tr className="table-head">
          {columns.map((column) => (
            <th
              key={column.key}
              style={{
                textAlign: column.key === 'name' ? 'left' : 'center',
                fontFamily: 'Avenir',
              }}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            style={{ cursor: 'pointer' }}
            key={index}
            className="table-row"
            onClick={() => {
              if (listType === 'patients') {
                navigate(`${baseRedirectUrl + item?.id}`);
              } else {
                navigate(
                  `${baseRedirectUrl}?claimId=${item?.claimId}&locationId=${item.locationId}`
                );
              }
            }}
          >
            {columns.map((column) => (
              <td
                key={column.key}
                style={{
                  textAlign: column.key === 'name' ? 'start' : 'center',
                }}
              >
                {column.key === 'name' ? (
                  <div className="custom-table-image">
                    <img
                      style={{ marginRight: '50px' }}
                      src={item[column.key].imageSrc}
                      alt={item[column.key].alt}
                      className=""
                    />
                    <p style={{ marginLeft: '-40px' }}>
                      {item[column.key].text}
                    </p>
                  </div>
                ) : (
                  item[column.key]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default GeneralList;
