import React, { useState } from 'react';
import styles from './createTherapy.module.scss';
import CustomRadio from '../../../CustomRadioButton/CustomRadioButton';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { setTherapy } from 'redux/slices/currentCarePlan';

const CreateTherapy = ({ closeModal, therapy }) => {
  const [charCount, setCharCount] = useState(0);
  const [therapyType, setTherapyType] = useState(therapy?.therapyType || '');
  const [otherInterventions, setOtherInterventions] = useState(
    therapy?.otherInterventions || ''
  );
  const [safePlanning, setSafePlanning] = useState(therapy?.hasSafetyPlanning);
  const maxCharacters = 120;
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const inputText = event.target.value;
    setCharCount(inputText.length);
    setOtherInterventions(inputText);
  };
  const handleTherapyTypeChange = (value) => {
    setTherapyType(value);
  };

  const handleSafePlanningChange = (value) => {
    setSafePlanning(value);
  };

  const handleTherapyFinish = () => {
    dispatch(
      setTherapy({
        therapy: {
          therapyType,
          hasSafetyPlanning: safePlanning,
          otherInterventions,
        },
      })
    );
    closeModal();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Therapy</h1>
        <span>Prescribe therapy for this patient.</span>
      </div>
      <div className={styles.contents}>
        <div className={styles.left}>
          <div>
            <h1>Type of therapy</h1>
            <CustomRadio
              label="Cognitive behavioral therapy (CBT)"
              name="radio-group"
              value={therapyType}
              options={[
                {
                  label: 'Cognitive behavioral therapy (CBT)',
                  value: 'Cognitive behavioral therapy (CBT)',
                },

                { label: 'Others (Specify)', value: 'Others (Specify)' },
              ]}
              onChange={handleTherapyTypeChange}
            />
          </div>
          <div className={styles.safety}>
            <h1>Has Safety Planning been discussed?</h1>
            <CustomRadio
              label="Yes"
              name="discussion"
              value={safePlanning}
              onChange={handleSafePlanningChange}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
          </div>
        </div>
        <div className={styles.right}>
          <h1>Other interventions</h1>
          <div className={styles.form}>
            <textarea
              onChange={handleInputChange}
              value={otherInterventions}
              maxLength={maxCharacters}
              rows={4}
              placeholder="Write something"
            />
            <span
              className={charCount === maxCharacters ? styles.exceeded : ''}
            >
              {charCount} / {maxCharacters} characters
            </span>
          </div>
          <div className={styles.done}>
            <Button
              label={'Done'}
              type={'button'}
              onClick={handleTherapyFinish}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTherapy;
