import React, { useEffect, useState } from 'react';
import { api } from 'api';
import useSWR from 'swr';
import { fetcher } from 'api';
import styles from './medicareClaims.module.scss';
import GoBackButton from '../../../GoBackButton/GoBackButton';
import CustomSelectDropdown from '../../../CustomSelectDropdown/CustomSelectDropdown';
import CustomInput from '../../../CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handleNextPlanStep } from '../../../../redux/slices/carePlanSlice';
import Modal from '../../../Modal/Modal';
import checkIcon from '../../../../assets/logo/icon-check.svg';
import { setMedicareResponseData } from 'redux/slices/medicareClaimSlice';
import Button from 'components/Button';
import alertIcon from '../../../../assets/icons/AlertCircle.svg';
import moment from 'moment';

import { useLocation } from 'react-router-dom';
import addIcon from '../../../../assets/logo/add.svg';
import { useBillingContext } from '../amount/billingContext';
import { setSelectedItem } from 'redux/slices/billingSlices';
import { toast } from 'react-toastify';
import { DatePicker } from 'antd';

const MedicareClaims = () => {
  const { user: practitioner } = useSelector((state) => state.auth) || {};
  const { user } = useSelector((state) => state.carePlanSummary);

  const { apiResponse } = useBillingContext();

  const [providerNum, setProviderNum] = useState('');

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [canNavigate, setCanNavigate] = useState(true);

  const { patient, _id, billingDetails } =
    useSelector((state) => state.currentCarePlan) || {};
  const careplanId = _id;

  const [openModal, setOpenModal] = useState(false);

  const [firstName, setFirstName] = useState(patient?.firstName || '');
  const [lastName, setLastName] = useState(patient?.lastName || '');
  const [gender, setGender] = useState(patient?.sex || '');
  const [additionalItems, setAdditionalItems] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState(
    patient?.dateOfBirth
      ? new Date(patient.dateOfBirth).toISOString().split('T')[0]
      : ''
  );

  const [medicareId, setMedicareId] = useState(patient?.medicareNum || '');
  const currentDate = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState(currentDate);

  const [referralDate, setReferralDate] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const response = await api.get('/files/medicare-codes');
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };
  const { data } = useSWR(['/files/medicare-codes'], fetchData);
  const [selectedItemNumber, setSelectedItemNumber] = useState('');
  const [scheduleFee, setScheduleFee] = useState('');
  const [description, setDescription] = useState('');
  const [medicareReferralNumber, setMedicareReferralNumber] = useState(
    patient?.medicareReferralNumber || ''
  );
  const [locationId, setLocationId] = useState(
    practitioner?.practitionerLocations.length > 0
      ? practitioner.practitionerLocations[0].locationId.toString()
      : ''
  );

  const [periodType, setPeriodType] = useState('Specialist');
  const isPsychiatrist = practitioner?.type === 'Psychiatrist';
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);
  const billtype = queryParams.get('billtype');

  const [successMessage, setSuccessMessage] = useState('');
  const [selectedAdditionalItem, setSelectedAdditionalItem] = useState({
    selectedItemNumber: '',
    scheduleFee: '',
    description: '',
  });
  const [itemCounter, setItemCounter] = useState(1);
  const [modalDone, setModalDone] = useState(false);

  const transformedItems = data?.data?.map((da) => ({
    ...da,
    itemNumber: da.itemNum,
    description: da.description,
    chargeAmount: da.fee,
    value: da.itemNum,
    label: da?.type,
  }));

  const handleAddAdditionalItem = () => {
    setItemCounter((prevCounter) => prevCounter + 1);
    setAdditionalItems((prevItems) => [
      ...prevItems,
      {
        selectedItemNumber: '',
        scheduleFee: '',
        date: '',
        description: '',
        itemNumber: `Item number ${itemCounter + 1}`,
      },
    ]);
  };

  const handleAdditionalItemChange = (index, field, value) => {
    setAdditionalItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleValidate = async () => {
    try {
      setLoading(true);
      setError(null);
      const allItems = [
        {
          chargeAmount: scheduleFee.toString(),
          date: date,
          itemNumber: selectedItemNumber.toString(),
        },
        ...additionalItems.map((item) => ({
          chargeAmount: item.scheduleFee.toString(),
          date: item.date,
          itemNumber: item.selectedItemNumber.toString(),
        })),
      ];

      const isPsychiatrist = practitioner?.type === 'Psychiatrist';
      const formattedReferralDate = moment(referralDate).format('YYYY-MM-DD');

      const requestData = {
        locationId,
        userId: user?.id,
        provider: { servicing: practitioner?.providerNumber },
        items: allItems,
        patient: {
          dateOfBirth,
          medicare: { number: medicareId, ref: medicareReferralNumber },
          gender,
          name: { first: firstName, family: lastName },
        },
        type: billingDetails?.type,
        flags: {
          serviceType: isPsychiatrist ? 'S' : 'O',
          accountPaid: 'Y',
        },
        location: {
          name:
            practitioner?.practitionerLocations.find(
              (location) => location.locationId.toString() === locationId
            )?.name || 'VIRTUAL',
          type: 'V',
        },
        ...(isPsychiatrist
          ? {
              referral: {
                date: formattedReferralDate,
                periodType: 'S',
                provider: providerNum,
              },
            }
          : {}),
        transaction: apiResponse?.data?.id,
      };

      const response = await api.post(
        `/claims/bill?careplanId=${careplanId}`,
        requestData
      );

      dispatch(setMedicareResponseData(response.data));

      if (response.data?.status === true) {
        setOpenModal(true);
        setCanNavigate(true);
        setModalDone(true);
      }
      setLoading(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response?.data?.status === false
      ) {
        let errorMessage;
        const errorMessageArray = error.response.data.message?.errors;
        if (Array.isArray(errorMessageArray)) {
          errorMessage = errorMessageArray.map((err) => err.message).join('');
        } else {
          errorMessage = error.response?.data?.message?.message;
        }

        setError({
          message: errorMessage,
        });

        toast.error(errorMessage);
        setCanNavigate(false);
      }

      setLoading(false);
    }
  };

  const handleModalDone = () => {
    if (modalDone && canNavigate) {
      dispatch(
        setSelectedItem({ itemNumber: selectedItemNumber, scheduleFee })
      );

      navigate('/create-care-plan/billing-filled');
    }
  };

  const active = useSelector((state) => state.carePlan.activePlanStep) || 0;

  useEffect(() => {
    setLoading(true);
    if (active) {
      dispatch(handleNextPlanStep({ step: 3 }));
    }
    setLoading(false);
  }, [active]);

  const handleItemSelect = (selectedItemNumber) => {
    const selectedData = transformedItems.find(
      (item) => item.itemNumber === selectedItemNumber
    );

    if (billingDetails?.formattedBillingType === 'Medicare' && selectedData) {
      // Set the scheduleFee from the state obtained from useBillingContext()
      setScheduleFee(billingDetails?.amount);
      setDescription(selectedData.description);
    } else {
      setScheduleFee(selectedData.chargeAmount);
      setDescription(selectedData.description);
    }

    setSelectedItemNumber(selectedItemNumber);
  };

  const handleDeleteItem = (index) => {
    setAdditionalItems((prevItems) =>
      prevItems.filter((item, i) => i !== index)
    );

    if (additionalItems.length === 1) {
      setItemCounter(1);
    }
  };

  return (
    <>
      <div className={styles.root}>
        <div>
          <GoBackButton to={-1} />
        </div>

        {error && (
          <div className={styles.errorContainer}>
            <img src={alertIcon} alt="" />
            <div>
              <p>{error.message}</p>
            </div>
            <div
              className={styles.errorCancel}
              role="button"
              onClick={() => setError(false)}
            >
              X
            </div>
          </div>
        )}
        <div className={styles.contents}>
          <div className={styles.container}>
            <div className={styles.header}>
              <h1>Medicare Billing</h1>
              <div>
                <h2>2. Claims</h2>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.left_content}>
                <CustomInput
                  name="Type of billing"
                  label="Type of billing:"
                  name="type"
                  value={billingDetails?.type || billtype}
                />

                <CustomInput
                  name="firstName"
                  label="First Name:"
                  value={firstName}
                  onChange={(value) => setFirstName(value)}
                />

                <CustomInput
                  name="Gender"
                  label="Gender:"
                  value={gender}
                  onChange={(value) => setGender(value)}
                />

                <CustomInput
                  type="number"
                  name="IRN"
                  label="IRN(Reference Number):"
                  value={medicareReferralNumber}
                  onChange={(value) => setMedicareReferralNumber(value)}
                />
              </div>
              <div className={styles.right_content}>
                <CustomInput
                  type="number"
                  value={medicareId}
                  onChange={setMedicareId}
                  name="medicareNumber"
                  label="Medicare Number:"
                />
                <CustomInput
                  name="LastName"
                  label="LastName:"
                  value={lastName}
                  onChange={(value) => setLastName(value)}
                />
                <CustomInput
                  name="DateOfBirth"
                  label="Date of Birth:"
                  value={dateOfBirth}
                  onChange={(value) => setDateOfBirth(value)}
                />

                <div className={styles.locate_field}>
                  <CustomSelectDropdown
                    className={styles.largeDropdown}
                    label="Location:"
                    value={locationId}
                    data={practitioner.practitionerLocations.map(
                      (location) => ({
                        label: location.name,
                        value: location.locationId.toString(),
                      })
                    )}
                    onChange={(value) => setLocationId(value)}
                    height="45px"
                  />
                </div>
              </div>
              <div className={styles.psych_content}>
                {isPsychiatrist && (
                  <>
                    <div className={styles.psych_header}>Referral</div>
                    <div className={styles.psych_fields}>
                      <CustomInput
                        name="date"
                        type="date"
                        label="Referral Date:"
                        value={referralDate}
                        onChange={(value) => setReferralDate(value)}
                      />

                      <CustomInput
                        label="Period Type"
                        name="PeriodType"
                        value={periodType}
                        onChange={(value) => setPeriodType(value)}
                      />
                      <CustomInput
                        label="Referral Provider Number"
                        name="Referral Provider Number"
                        value={providerNum}
                        onChange={(value) => setProviderNum(value)}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className={styles.item_field}>
                <CustomSelectDropdown
                  className={styles.largeDropdown}
                  showSearch={true}
                  label="Item number:"
                  value={selectedItemNumber}
                  data={transformedItems}
                  onChange={(value) => handleItemSelect(value)}
                  height="45px"
                />
              </div>

              <div className={styles.foot_content}>
                <div className={styles.schedule_fee}>
                  <CustomInput
                    value={scheduleFee}
                    name="scheduleFee"
                    className={styles.dropdown}
                    label="Schedule Fee:"
                    onChange={(value) => setScheduleFee(value)}
                  />
                </div>
                <div className={styles.serviceInput}>
                  <label>Date of service:</label>
                  <DatePicker
                    onChange={(e, val) => setDate(val)}
                    style={{ padding: '12px' }}
                  />
                </div>
              </div>
              <div className={styles.description}>{description}</div>

              {additionalItems.map((item, index) => (
                <React.Fragment key={index}>
                  <div className={styles.item_field}>
                    <CustomSelectDropdown
                      className={styles.largeDropdown}
                      showSearch={true}
                      label={item.itemNumber}
                      value={item.selectedItemNumber}
                      data={transformedItems}
                      onChange={(value) => {
                        handleAdditionalItemChange(
                          index,
                          'selectedItemNumber',
                          value
                        );
                        const selectedData = transformedItems.find(
                          (item) => item.itemNumber === value
                        );

                        if (selectedData) {
                          setAdditionalItems((prevItems) => {
                            const newItems = [...prevItems];
                            newItems[index].scheduleFee =
                              billingDetails?.formattedBillingType ===
                              'Medicare'
                                ? billingDetails?.amount
                                : selectedData.chargeAmount;
                            newItems[index].description =
                              selectedData.description;
                            return newItems;
                          });
                        }
                      }}
                      height="45px"
                    />
                  </div>

                  <div className={styles.foot_content}>
                    <div className={styles.schedule_fee}>
                      <CustomInput
                        value={item.scheduleFee}
                        name="scheduleFee"
                        label="Schedule Fee:"
                        onChange={(value) =>
                          handleAdditionalItemChange(
                            index,
                            'scheduleFee',
                            value
                          )
                        }
                      />
                    </div>
                    <div className={styles.serviceInput}>
                      <label>Date of service:</label>
                      <DatePicker
                        onChange={(e, val) => {
                          handleAdditionalItemChange(index, 'date', val);
                        }}
                        style={{ padding: '12px' }}
                      />
                    </div>
                  </div>
                  <div className={styles.description}>{item.description}</div>
                  <div
                    className={styles.deleteButton}
                    onClick={() => handleDeleteItem(index)}
                  >
                    Delete
                  </div>
                </React.Fragment>
              ))}

              <div onClick={handleAddAdditionalItem} className={styles.add}>
                <img src={addIcon} alt="" />
                <span>Add Items</span>
              </div>
              <div className={styles.buttons}>
                <Button
                  onClick={handleValidate}
                  type="submit"
                  isLoading={loading}
                  label="Submit"
                />
              </div>
            </div>
          </div>
        </div>
        {error ? null : (
          <Modal
            isOpen={openModal}
            onClose={() => setOpenModal(false)}
            children={
              <div className={styles.modalRoot}>
                <img src={checkIcon} alt="" />
                <h1>Billing Successful</h1>
                <p>
                  This bill has been queued for sending. A notification will be
                  sent to your patient once this has been approved.
                </p>

                <Button
                  onClick={handleModalDone}
                  type="submit"
                  isLoading={loading}
                  label="Done"
                />
              </div>
            }
          />
        )}
      </div>
    </>
  );
};

export default MedicareClaims;
