import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './goBackButton.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextPlanStep } from 'redux/slices/carePlanSlice';

const GoBackButton = ({ to, onClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activePlanStep } = useSelector((state) => state.carePlan);
  const handleGoBack = () => {
    dispatch(handleNextPlanStep({ step: activePlanStep - 1 }));
    navigate(to);
  };

  return (
    <button
      onClick={onClick ? onClick : handleGoBack}
      className={styles.backButton}
      alt=""
    >
      {'< '}
      {'Go Back'}
    </button>
  );
};

export default GoBackButton;
