import React, { useState } from 'react';
import styles from './billingModal.module.scss';
import { useNavigate } from 'react-router-dom';
import { api } from 'api';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { toast } from 'react-toastify';

const BillingModal = ({ billingDetails, locationId, claimId, billtype }) => {
  const status = billingDetails ? billingDetails?.data?.status : 'PENDING';
  const medicareClaimId = billingDetails
    ? billingDetails?.data?.medicareClaimId
    : '';
  const timeReceived = billingDetails ? billingDetails?.data?.timeReceived : '';
  const transactionId = billingDetails
    ? billingDetails?.data?.transactionId
    : '';
  const type = billingDetails ? billingDetails?.data?.type : '';
  const chargeAmount = billingDetails?.data?.items[0]?.chargeAmount || 0;
  const itemNumber = billingDetails?.data?.items[0]?.itemNumber || '';

  const firstName = billingDetails?.data?.patient?.name?.first || '';
  const lastName = billingDetails?.data?.patient?.name?.family || '';

  const dateOfBirth = billingDetails?.data?.patient?.dateOfBirth || '';
  const gender = billingDetails?.data?.patient?.gender || '';

  const referralDate = billingDetails?.data?.referral?.date || '';
  const referralProvider = billingDetails?.data?.referral?.provider || '';

  const locationName = billingDetails?.data?.location?.name || '';
  const locationType = billingDetails?.data?.location?.type || '';
  const claim = billingDetails?.data?.claimId || '';
  const [buttonLoading, setButtonLoading] = useState(false);
  const { user: practitioner } = useSelector((state) => state.auth) || {};

  const isPsychiatrist = practitioner?.type === 'Psychiatrist';

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setButtonLoading(true);
      const data = {
        locationId: locationId,
        claimId: claimId.toString(),
      };

      const response = await api.post(`/claims/send-claim`, data);

      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.status === true) {
          setButtonLoading(false);
        } else {
          toast.error(responseData?.data?.message);
        }
      } else {
        console.error('Claim submission failed. Status:', response.status);
        toast.error('Claim submission failed. Status');
      }
    } catch (error) {
      console.error('Error during claim submission:', error);

      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        toast.error(` ${errorMessage}`);
      } else {
        toast.error(`Error during claim submission: ${error.message}`);
      }
      setButtonLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await api.get(`/pdf/?claimId=${claim}`);
      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.status === true) {
          window.open(responseData.data);
        } else {
          console.error('Download failed. Status:', responseData.status);
        }
      } else {
        console.error('Download failed. Status:', response.status);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };

  const handleResubmitClaim = () => {
    navigate(`/create-care-plan/medicare-claims?billtype=${billtype}`);
  };

  let downloadButtonText = 'null';

  switch (type) {
    case 'Medicare':
      downloadButtonText = 'Download Lodgement Form';
      break;
    case 'BulkBill':
      downloadButtonText = 'Download DB4 Form';
      break;
    default:
      break;
  }

  let submitButton = null;
  let resubmitButton = null;
  // @Todo: connect with Nachi and Prince to understand the status and the button to be displayed
  // const resubmitClaimStatus = ['MEDICARE_PENDABLE', 'MEDICARE_REJECTED']
  // const submitToOperatorStatus = ['MEDICARE_PENDABLE']

  switch (status) {
    case 'MEDICARE_PENDABLE':
      submitButton = (
        <Button
          onClick={handleSubmit}
          label="Submit to operator"
          isLoading={buttonLoading}
          style={{
            display: 'flex',
            width: '200px',
            height: '50px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      );
      resubmitButton = (
        <div onClick={handleResubmitClaim} className={styles.resubmit}>
          Resubmit Claim
        </div>
      );
      break;
    case 'MEDICARE_REJECTED':
      resubmitButton = (
        <button onClick={handleResubmitClaim} className={styles.resubmit}>
          Resubmit Claim
        </button>
      );
      break;
    default:
      break;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Billing</h1>
        <span>Payment for mental health treatment</span>
        <p>{status}</p>
      </div>
      <div className={styles.contentsContainer}>
        <Content label="Status" value={status} />
        <Content label="Medicare Claim ID" value={medicareClaimId} />
        <Content label="Transaction ID" value={transactionId} />
        <Content label="Time received" value={timeReceived} />

        <Content label="Type" value={type} />
        <Content label="Charge Amount" value={`$${chargeAmount}`} />
        <Content label="Item Number" value={itemNumber} />
        <Content label="Patient Name" value={`${firstName} ${lastName}`} />
        <Content label="Date of Birth" value={dateOfBirth} />
        <Content label="Gender" value={gender} />
        {isPsychiatrist && (
          <>
            <Content label="Referral Date" value={referralDate} />
            <Content label="Referral Provider" value={referralProvider} />
          </>
        )}
        <Content label="Location Name" value={locationName} />
        <Content label="Location Type" value={locationType} />
      </div>

      <div className={styles.btn}>
        {resubmitButton}

        <button className={styles.resubmit} onClick={handleDownload}>
          {downloadButtonText}
        </button>
        {submitButton}
      </div>
    </div>
  );
};

const Content = ({ label, value }) => (
  <div className={styles.content}>
    <span>{label}</span>
    <p>{value}</p>
  </div>
);

export default BillingModal;
