import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: 'profile',
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateAccountTab: (state, action) => {
      state.currentTab = action.payload.tab;
    },
  },
});

export const { updateAccountTab } = accountSlice.actions;

export default accountSlice.reducer;
