import OnboardingLayout from '../layouts/OnboardingLayout';
import './VerificationPending.scss';

const KycPending = () => {
  return (
    <OnboardingLayout>
      <div className="verification-pending-container">
        <div>
          <h1>KYC pending</h1>
          <p>
            Your account is under review. Please wait for the admin to approve
            your account
          </p>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default KycPending;
