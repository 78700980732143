import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './profileDisplayCard.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input, { Textarea } from './../FormInput';
import defaultImage from '../../assets/logo/userAvatar.svg';
import Button from 'components/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaTrashCan } from 'react-icons/fa6';
import { LuPencilLine } from 'react-icons/lu';
import { FiPlus } from 'react-icons/fi';
import { DatePicker, Tabs } from 'antd';
import CustomSelectDropdown from 'components/CustomSelectDropdown/CustomSelectDropdown';
import Modal from 'components/Modal/Modal';
import { Modal as AntModal } from 'antd';
import { Upload } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  addEducation,
  removeEducation,
  updateEducation,
} from 'redux/slices/authSlice';
import dayjs from 'dayjs';
import { fetcher } from 'api';
import useSWR from 'swr';

const ProfileDisplayCard = () => {
  const [practitioner, setPractitioner] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.auth) || {};
  const { data, error, isLoading, isValidating } =
    useSWR([`/practitioners/languages-interest`], fetcher) || {};
  const {
    data: userProfileData,
    error: userProfileError,
    isLoading: userProfileLoading,
    isValidating: userProfileValidatng,
  } = useSWR([`/users/${user._id}`], fetcher) || {};

  const { Dragger } = Upload;
  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      setSelectedFile(file);
      return false; // Prevent automatic upload
    },
    onRemove: () => {
      setSelectedFile(null);
    },
    fileList: selectedFile ? [selectedFile] : [],
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    suburb: Yup.string().required('Suburb is required'),
    postalCode: Yup.string().required('Postal code is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      suburb: '',
      postalCode: '',
      bio: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
    },
  });
  const { errors, resetForm, setValues } = formik;

  useEffect(() => {
    setValues({
      bio: userProfileData?.businessInformation?.bio,
    });
  }, [userProfileData]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Business Details',
      children: (
        <BusinessDetailForm
          formik={formik}
          user={userProfileData}
          interestAndLanguage={data}
        />
      ),
    },
    {
      key: '2',
      label: 'Personal Details',
      children: <PersonalDetailForm formik={formik} user={user} />,
    },
  ];

  return (
    <div className={styles['profile_wrapper']}>
      <div className={styles['profile_header']}>
        <div className={styles['img_container']}>
          <img
            src={
              selectedFile
                ? URL.createObjectURL(selectedFile)
                : practitioner.imageUrl || defaultImage
            }
            alt="profile_pic"
          />
        </div>

        <div className={styles['bio_item']}>
          <h1>{`${user?.firstName} ${user?.lastName}`}</h1>
          <h3>Sydney, Australia</h3>
          <label
            onClick={() => setIsOpen(true)}
            className={styles['picture_btn']}
          >
            Upload Picture
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      <Modal
        containerWrapper={styles.containerWrapper}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        children={
          <div className={styles.uploadWrapper}>
            <h1> Upload Picture</h1>
            <Dragger {...props}>
              <div className={styles.dragContent}>
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="profile_pic"
                  />
                ) : (
                  <>
                    <p>Drag and drop file</p>
                    <span>or click this area to select</span>
                  </>
                )}
              </div>
            </Dragger>

            <div className={styles.uploadBtnWrapper}>
              <Button label={'Upload'} />
            </div>
          </div>
        }
      />

      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        className={styles['custom-tab-wrapper']}
      />
    </div>
  );
};

export default ProfileDisplayCard;

export const BusinessDetailForm = ({ formik, user, interestAndLanguage }) => {
  const [selectedAreasOfInterest, setSelectedAreasOfInterest] = useState([]);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [openEduModal, setOpenEduModal] = useState(false);
  const [editEduModal, setEditEduModal] = useState(false);
  const [removeEduWarnModal, setRemoveEduWarnModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [educationItem, setEducationItem] = useState({
    almaMater: '',
    degree: '',
    year: '',
  });
  const [editEducationItem, setEditEducationItem] = useState({
    almaMater: '',
    degree: '',
    year: '',
  });
  console.log(user);

  useEffect(() => {
    if (user) {
      setSelectedLanguages(
        user?.businessInformation?.languages.map((lang) => ({
          label: lang.name,
          value: lang.name,
        })) || []
      );

      setSelectedAreasOfInterest(
        user?.businessInformation?.areaOfInterests.map((interest) => ({
          label: interest.name,
          value: interest.name,
        }))
      );
    }
  }, [user]);

  useEffect(() => {
    const editItem = user?.education[editIndex];
    if (editItem) {
      setEditEducationItem(editItem);
      setEditEduModal(true);
    }
  }, [editIndex]);
  const dispatch = useDispatch();
  const interestList =
    interestAndLanguage?.interests.map((interest) => ({
      label: interest.name,
      value: interest.name,
    })) || [];

  const langList = interestAndLanguage?.languages.map((language) => ({
    label: language.name,
    value: language.name,
  }));

  console.log(selectedLanguages, 'selectedLanguages');

  return (
    <form className={styles['profile_input']} onSubmit={formik.handleSubmit}>
      <div className={styles['input-container']}>
        <div className={styles['input-row']}>
          <Textarea
            name="bio"
            label={'Bio'}
            value={formik.values.bio}
            onBlur={formik.handleBlur('bio')}
            onChange={formik.handleChange}
            error={formik.touched.bio && formik.errors.bio}
          />
        </div>
        <div className={styles.educationSection}>
          <div className={styles.educationHeader}>
            <h3>Education</h3>
            <div className={styles.plusContainer}>
              <FiPlus
                color="#FD9C42"
                cursor="pointer"
                onClick={() => setOpenEduModal(true)}
              />
              <span>Add New</span>
            </div>
          </div>
          <div className={styles.educationListWrapper}>
            {user?.education.length > 0 ? (
              user.education.map((ed, index) => (
                <div className={styles.educationList}>
                  <div className={styles.educationItem}>
                    <h4>{ed.almaMater + ', ' + ed.degree}</h4>
                    <span>{ed.year}</span>
                  </div>
                  <div className={styles.educationItemActions}>
                    <div className={styles.pencilContainer}>
                      <LuPencilLine
                        color="#FD9C42"
                        cursor="pointer"
                        onClick={() => {
                          setEditIndex(index);
                        }}
                      />
                    </div>
                    <div className={styles.trashContainer}>
                      <FaTrashCan
                        cursor="pointer"
                        color="#A29D98"
                        onClick={() => {
                          setRemoveEduWarnModal(true);
                          setDeleteIndex(index);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noEducation}>No Education Records Yet</div>
            )}
          </div>
        </div>

        {/* add edu modal */}
        <AntModal
          footer={null}
          title="Education"
          open={openEduModal}
          onCancel={() => {
            setOpenEduModal(false);
            setEducationItem({
              almaMater: '',
              degree: '',
              year: '',
            });
          }}
          className={styles.eduModalContainerWrapper}
        >
          <div className={styles.eduModalWrapper}>
            <div className={styles['input-row']}>
              <Input
                required
                label="Name of Institution"
                value={educationItem.almaMater}
                onChange={(e) =>
                  setEducationItem({
                    ...educationItem,
                    almaMater: e.target.value,
                  })
                }
                error={formik.touched.lastName && formik.errors.lastName}
              />
            </div>
            <div className={styles['input-row']}>
              <div className={styles.datePickerContainer}>
                <label>Graduation Year</label>
                <DatePicker
                  disabledDate={(current) => {
                    return current && current > moment().endOf('day');
                  }}
                  value={
                    educationItem.year
                      ? dayjs(educationItem.year, 'YYYY')
                      : null
                  }
                  picker="year"
                  className={styles.eduDatePicker}
                  onChange={(e, val) => {
                    setEducationItem({
                      ...educationItem,
                      year: val,
                    });
                  }}
                  style={{ padding: '12px' }}
                />
              </div>

              <div className={styles.rowWrapper}>
                <Input
                  required
                  label={'Degree'}
                  value={educationItem.degree}
                  onChange={(e) =>
                    setEducationItem({
                      ...educationItem,
                      degree: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.eduModalBtn}>
              <Button
                label={'Save'}
                type={'submit'}
                onClick={() => {
                  dispatch(
                    addEducation({
                      almaMater: educationItem.almaMater,
                      degree: educationItem.degree,
                      year: educationItem.year,
                    })
                  );
                  setOpenEduModal(false);
                  setEducationItem({
                    almaMater: '',
                    degree: '',
                    year: null,
                  });
                }}
              />
            </div>
          </div>
        </AntModal>

        {/* warn delete edu modal */}
        <AntModal
          footer={null}
          title="Remove Education"
          open={removeEduWarnModal}
          onCancel={() => {
            setRemoveEduWarnModal(false);
          }}
        >
          <div>
            <p>
              Are you sure you want to remove this record ? This action cannot
              be undone
            </p>
            <div className={styles.warnModalBtnContainer}>
              <div className={styles.warnBtnInnerContainer}>
                <div className={styles.cancelBtnContainer}>
                  <Button
                    label={'Cancel'}
                    type={'submit'}
                    onClick={() => setRemoveEduWarnModal(false)}
                  />
                </div>
                <div className={styles.deleteBtnContainer}>
                  <Button
                    label={'Remove'}
                    type={'submit'}
                    onClick={() => {
                      if (deleteIndex) {
                        dispatch(removeEducation({ index: deleteIndex }));
                        setRemoveEduWarnModal(false);
                        setDeleteIndex(null);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </AntModal>

        {/* edit edu modal */}
        <AntModal
          on
          footer={null}
          title="Edit Education"
          open={editEduModal}
          onCancel={() => {
            setEditEduModal(false);
            setEditEducationItem({
              almaMater: '',
              degree: '',
              year: '',
            });
            setEditIndex(null);
          }}
          className={styles.eduModalContainerWrapper}
        >
          <div className={styles.eduModalWrapper}>
            <div className={styles['input-row']}>
              <Input
                required
                label="Name of Institution"
                value={editEducationItem.almaMater}
                onChange={(e) =>
                  setEditEducationItem({
                    ...editEducationItem,
                    almaMater: e.target.value,
                  })
                }
              />
            </div>
            <div className={styles['input-row']}>
              <div className={styles.datePickerContainer}>
                <label>Graduation Year</label>
                <DatePicker
                  disabledDate={(current) => {
                    return current && current > moment().endOf('day');
                  }}
                  value={
                    editEducationItem.year
                      ? dayjs(editEducationItem.year, 'YYYY')
                      : null
                  }
                  picker="year"
                  className={styles.eduDatePicker}
                  onChange={(e, val) => {
                    setEditEducationItem({
                      ...editEducationItem,
                      year: val,
                    });
                  }}
                  style={{ padding: '12px' }}
                />
              </div>

              <div className={styles.rowWrapper}>
                <Input
                  required
                  label={'Degree'}
                  value={editEducationItem.degree}
                  onChange={(e) =>
                    setEditEducationItem({
                      ...editEducationItem,
                      degree: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.eduModalBtn}>
              <Button
                label={'Save'}
                type={'submit'}
                onClick={() => {
                  dispatch(
                    updateEducation({
                      index: editIndex,
                      education: {
                        almaMater: editEducationItem.almaMater,
                        degree: editEducationItem.degree,
                        year: editEducationItem.year,
                      },
                    })
                  );
                  setEditEduModal(false);
                  setEditEducationItem({
                    almaMater: '',
                    degree: '',
                    year: null,
                  });
                  setEditIndex(null);
                }}
              />
            </div>
          </div>
        </AntModal>

        <div className={styles['input-row']}>
          <div className={styles.rowWrapper}>
            <Input
              label="Business email"
              name="businessEmail"
              value={user?.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && formik.errors.lastName}
            />
          </div>

          <div className={styles.rowWrapper}>
            <Input
              label={'Practice Phone Number'}
              name="phoneNumber"
              value={user?.phoneNumber}
              onChange={(value) => {
                formik.handleChange('phoneNumber')(value);
              }}
              onBlur={formik.handleBlur('phoneNumber')}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </div>
        </div>
        <div className={styles['input-row']}>
          <div className={styles.rowWrapper}>
            <Input
              label={'Suburb'}
              name="suburb"
              value={formik.values.suburb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur('suburb')}
              error={formik.touched.suburb && formik.errors.suburb}
            />
          </div>

          <div className={styles.rowWrapper}>
            <Input
              label={'Postal Code'}
              name="postalCode"
              value={user?.postalCode}
              onChange={(value) => {
                formik.handleChange('postalCode')(value);
              }}
              onBlur={formik.handleBlur('postalCode')}
              error={formik.touched.postalCode && formik.errors.postalCode}
            />
          </div>
        </div>
        <div className={styles['input-row']}>
          <div style={{ width: '100%' }}>
            <CustomSelectDropdown
              showIcon={true}
              showSearch={true}
              icon={<MdKeyboardArrowDown size={18} color="black" />}
              label={'Areas of Interest'}
              data={interestList}
              value={selectedAreasOfInterest}
              mode={'multiple'}
              onChange={(values) => {
                const selectedOptions = values.map((value) =>
                  interestList.find((interest) => interest.value === value)
                );
                if (selectedOptions) {
                  setSelectedAreasOfInterest(selectedOptions);
                }
              }}
            />
          </div>
        </div>
        <div className={styles['input-row']}>
          <div style={{ width: '100%' }}>
            <CustomSelectDropdown
              showSearch={true}
              value={selectedLanguages}
              label={'Languages'}
              icon={<MdKeyboardArrowDown size={18} color="black" />}
              showIcon={true}
              data={langList}
              mode={'multiple'}
              onChange={(values) => {
                const selectedOptions = values.map((value) =>
                  langList.find((language) => language.value === value)
                );
                if (selectedOptions) {
                  setSelectedLanguages(selectedOptions);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button label={'Save Changes'} type={'submit'} />
      </div>
    </form>
  );
};

export const PersonalDetailForm = ({ formik, user }) => {
  return (
    <form className={styles['profile_input']} onSubmit={formik.handleSubmit}>
      <div className={styles['input-container']}>
        <div className={styles['input-row']}>
          <div className={styles.rowWrapper}>
            <Input
              label="First Name"
              name="firstName"
              // value={user?.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && formik.errors.lastName}
            />
          </div>

          <div className={styles.rowWrapper}>
            <Input
              label={'Last Name'}
              name="lastName"
              // value={user?.phoneNumber}
              onChange={(value) => {
                formik.handleChange('phoneNumber')(value);
              }}
              onBlur={formik.handleBlur('phoneNumber')}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </div>
        </div>
        <div className={styles['input-row']}>
          <div className={styles.rowWrapper}>
            <Input
              label={'Personal Email'}
              name="personalEmail"
              // value={formik.values.suburb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur('suburb')}
              error={formik.touched.suburb && formik.errors.suburb}
            />
          </div>

          <div className={styles.rowWrapper}>
            <Input
              label={'Phone Number'}
              name="phoneNumber"
              // value={user?.postalCode}
              onChange={(value) => {
                formik.handleChange('postalCode')(value);
              }}
              onBlur={formik.handleBlur('postalCode')}
              error={formik.touched.postalCode && formik.errors.postalCode}
            />
          </div>
        </div>
        <div className={styles['input-row']}>
          <div className={styles.rowWrapper}>
            <Input
              label={'Suburb'}
              name="suburb"
              // value={formik.values.suburb}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur('suburb')}
              error={formik.touched.suburb && formik.errors.suburb}
            />
          </div>

          <div className={styles.rowWrapper}>
            <Input
              label={'Postal Code'}
              name="postalCode"
              // value={user?.postalCode}
              onChange={(value) => {
                formik.handleChange('postalCode')(value);
              }}
              onBlur={formik.handleBlur('postalCode')}
              error={formik.touched.postalCode && formik.errors.postalCode}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
