import React from 'react';
import styles from './customInput.module.scss';

const CustomInput = ({
  name,
  label,
  onChange,
  value,
  className,
  type,
  props,
}) => {
  return (
    <div className={`${styles.root} ${className}`}>
      <label>{label}</label>
      <input
        type={type}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
