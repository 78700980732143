import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextPlanStep } from '../../../redux/slices/carePlanSlice';
import RichEditor from '../../RichText/RichText';
import { stateToHTML } from 'draft-js-export-html';
import checkIcon from '../../../assets/logo/icon-check.svg';

import avatar from '../../../assets/logo/userAvatar.svg';
import editIcon from '../../../assets/icons/edit-icon.svg';
import downloadIcon from '../../../assets/icons/Download.svg';
import styles from './patientNote.module.scss';
import GoBackButton from '../../GoBackButton/GoBackButton';
import Button from 'components/Button';
import { getHTMLFromEditor, processString } from 'utils/editorHelpers';
import {
  setCurrentCarePlan,
  updatePatientNote,
} from 'redux/slices/currentCarePlan';
import { convertFromHTML, EditorState, ContentState } from 'draft-js';
import moment from 'moment';
import { updateCarePlan } from 'redux/actions/carePlanAction';
import { toast } from 'react-toastify';
import Modal from 'components/Modal/Modal';
import { useNavigate } from 'react-router-dom';

const PatientNote = () => {
  const currentCarePlan = useSelector((state) => state.currentCarePlan) || {};
  const { patientNote, patient, hasBeenModified, flow, _id } = currentCarePlan;
  let formatted = null;

  if (patientNote) {
    const parsed = processString(patientNote);
    formatted = EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(parsed))
    );
  }

  const [openEditor, setOpenEditor] = useState(false);
  const [editorState, setEditorState] = useState(formatted);
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    dispatch(handleNextPlanStep({ step: 4 }));
  }, []);

  const continueHandler = async () => {
    setLoading(true);
    try {
      const newCareplanObj = { ...currentCarePlan };
      delete newCareplanObj?.patientNoteDate;
      const payload = {
        userId: patient?._id,
        careplan: {
          ...newCareplanObj,
          assessment: currentCarePlan.assessment?._id,
          patientNote: patientNote,
          stepsCompleted: {
            ...currentCarePlan.stepsCompleted,
            stepFiveCompleted: true,
          },
        },
      };

      const { data } = await updateCarePlan(_id, payload);
      dispatch(
        setCurrentCarePlan({
          ...data.data,
          hasBeenModified: !hasBeenModified && flow === 'modify',
        })
      );

      setLoading(false);
      setSuccessModal(true);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <div className={styles.root}>
      <section className={styles.topName}>
        <div className={styles.image}>
          <img src={avatar} alt="" />
          <div>
            <h1>{patient?.firstName + ' ' + patient?.lastName}</h1>
            <span>Care Plan</span>
          </div>
        </div>
        <GoBackButton
          to={-1}
          onClick={() => navigate('/create-care-plan/billing')}
        />
      </section>
      <div className={styles.subHeaderContainer}>
        <div className={styles.header}>
          <h1>Patient Note</h1>
          <span>Provide additional patient note.</span>
        </div>
        {!openEditor && (
          <div className={styles.date}>
            <h1>Created on {moment(patientNote?.date).format('LL')}</h1>
            <div className={styles.editAndDownload}>
              <div
                role="button"
                onClick={() => setOpenEditor(true)}
                className={styles.edit}
              >
                <span>Edit</span>
                <img src={editIcon} alt="" />
              </div>
              <div className={styles.download}>
                <span role="button">Download as PDF</span>
                <img src={downloadIcon} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>

      <section className={styles.notes}>
        {!openEditor ? (
          <div className={styles.noEditor}>
            {editorState ? (
              <div className={styles.editedText}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: stateToHTML(editorState?.getCurrentContent()),
                  }}
                />
              </div>
            ) : (
              <>
                <h1>No patient note - yet</h1>
                <p>Patient note will note be made visible to the patient.</p>
                <div className={styles.ButtonWrapper}>
                  <Button
                    onClick={() => setOpenEditor(true)}
                    label={'  Create Patient Note'}
                    type={'button'}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className={styles.editor}>
              <RichEditor
                initialState={editorState}
                onChange={handleEditorChange}
              />
            </div>
          </>
        )}
      </section>
      {openEditor ? (
        <div className={styles.editorButtons}>
          <div className={styles.editorBtnWrapper}>
            <div className={styles.cancelBtn}>
              <Button
                type={'button'}
                onClick={() => setOpenEditor(false)}
                label={'Cancel'}
              />
            </div>
            <div className={styles.saveBtn}>
              <Button
                type={'button'}
                onClick={() => {
                  dispatch(
                    updatePatientNote({
                      patientNote: getHTMLFromEditor(editorState),
                    })
                  );
                  setOpenEditor(false);
                }}
                label={'Save'}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: '20%', alignSelf: 'flex-end', marginTop: '15px' }}>
          <Button
            label={'Submit'}
            onClick={continueHandler}
            isLoading={loading}
          />
        </div>
      )}

      <Modal
        isOpen={successModal}
        onClose={() => setSuccessModal(false)}
        children={
          <div className={styles.carePlanSuccessModal}>
            <img src={checkIcon} alt="" />
            <h1>Care Plan Approved Successfully</h1>
            <p>
              You can generate a referral note that can be emailed or printed
              for a psychologist/psychiatric.
            </p>
            <div>
              <Button
                label={'Careplan Summary'}
                type={'button'}
                onClick={() =>
                  navigate(
                    `/dashboard/careplan-summary/${
                      currentCarePlan?._id || currentCarePlan?.id
                    }`
                  )
                }
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PatientNote;
