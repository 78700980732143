import React from 'react';
import './icon.scss';

const DashboardIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    className="icon-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 3.5V9.5H3V3.5H21ZM5 5.5H19V7.5H5V5.5Z"
      className="icon-svg"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 11.5V21.5H13V11.5H21ZM15 13.5H19V19.5H15V13.5Z"
      className="icon-svg"
    />
    <path d="M3 11.5H11V13.5H3V11.5Z" className="icon-svg" />
    <path d="M11 15.5H3V17.5H11V15.5Z" className="icon-svg" />
    <path d="M3 19.5H11V21.5H3V19.5Z" className="icon-svg" />
  </svg>
);

export default DashboardIcon;
