import './AsrsQuestions.scss';

const AsrsQuestion = ({ title, question }) => {
  if (typeof title !== 'string' || typeof question !== 'string') {
    throw new Error(
      'AsrsQuestion component: title and question props must be strings.'
    );
  }

  return (
    <div className="question_container">
      <h3>{title}</h3>
      <p>{question}</p>
    </div>
  );
};

export default AsrsQuestion;
