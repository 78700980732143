import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activePlanStep: 0,
};

const carePlanSlice = createSlice({
  name: 'carePlan',
  initialState,
  reducers: {
    handleFinish: () => {
      return initialState;
    },
    handleNextPlanStep: (state, action) => {
      return {
        ...state,
        activePlanStep: action.payload.step,
      };
    },
    handlePreviousPlanStep: (state, action) => {
      if (state.activePlanStep === 0) {
        return;
      }
      return {
        activePlanStep: action.payload.step,
      };
    },
  },
});

export const { handleNextPlanStep, handlePreviousPlanStep, handleFinish } =
  carePlanSlice.actions;

export default carePlanSlice.reducer;
