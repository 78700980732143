import React, { useEffect, useState } from 'react';
import styles from './progressBar.module.scss';
import CircleBar from './CircleBar';
import { useSelector } from 'react-redux';

const ProgressBar = () => {
  const [circle] = useState(5);
  const [width, setWidth] = useState(0);
  const active = useSelector((state) => state.carePlan.activePlanStep) || 0;

  useEffect(() => {
    setWidth((90 / (circle - 1)) * active);
  }, [circle, active]);

  const arr = [];
  const tabName = ['Assessment', 'Plan', 'Schedule', 'Billing', 'Patient Note'];

  for (let i = 1; i <= circle; i++) {
    arr.push(
      <CircleBar
        key={i}
        active={active}
        className={i - 1 <= active ? styles['circleActive'] : styles.circle}
        name={tabName.filter((name, index) => index + 1 === i && name)}
      >
        {i}
      </CircleBar>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.progressBar}>
          <div className={styles.progress} style={{ width: width + '%' }} />
          {arr}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
