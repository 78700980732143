import React, { useEffect, useState } from 'react';
import DetailsInformation from './DetailsInformation/DetailsInformation';
import DetailsGoal from './detailsGoal/DetailsGoal';
import DetailsCareTeam from './DetailsCareTeam/DetailsCareTeam';
import userAvatar from '../../assets/logo/userAvatar.svg';
import styles from './dashboardUserDetails.module.scss';
import GoBackButton from '../../components/GoBackButton/GoBackButton';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCarePlanSummary } from '../../redux/actions/patientAction';
import ReferalNoteAndAppointment from './ReferalNoteAppointment/ReferalNoteAndAppointment';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { getLastCompletedStepNumber } from '../../utils/getLastCompletedSteps';

import { Spin } from 'antd';
import {
  resetCurrentCarePlan,
  setCurrentCarePlan,
} from 'redux/slices/currentCarePlan';
import { resetBilling } from 'redux/slices/billingSlices';

const DashboardUserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedInDoctor = useSelector((state) => state.auth.user);
  useEffect(() => {
    dispatch(resetCurrentCarePlan());
    dispatch(resetBilling());
  });
  const {
    careTeam,
    assessment,
    assessmentOverview,
    careplanHistory,
    user,
    loading,
    asrs,
  } = useSelector((state) => state.carePlanSummary) || {};
  const params = useParams();

  useEffect(() => {
    dispatch(fetchCarePlanSummary(params.id));
  }, []);

  const lastCarePlan = careplanHistory[0] || {};
  const lastCareplanDate = lastCarePlan?.createdAt || new Date();

  const step = getLastCompletedStepNumber(lastCarePlan);
  const stepToNavigate = {
    1: '/',
    2: '/plan',
    3: '/schedule',
    4: '/billing',
    5: '/patient-note',
  };

  const handleContinue = () => {
    dispatch(setCurrentCarePlan({ ...lastCarePlan, flow: 'continue' }));
    navigate(`/create-care-plan${stepToNavigate[step]}`);
  };

  const shouldCreateNewCarePlan = careplanHistory?.length === 0;
  const shouldContinueCarePlan =
    careplanHistory?.length > 0 && !lastCarePlan?.isCareplanCompleted;
  const shouldAllowContinue =
    shouldContinueCarePlan &&
    lastCarePlan?.practitioner?.id?.toString() ===
      loggedInDoctor?._id?.toString();

  return (
    <div className={styles.root}>
      <GoBackButton to={-1} />
      <div className={styles.patientDetails}>
        {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(255, 255, 255, 0.95)',
              zIndex: 1000,
            }}
          >
            <Spin tip="Loading..." className={styles['ant-spin-dot-item']} />
          </div>
        )}

        <h1 className={styles.patientHeader}>Patient Details</h1>
        <div className={styles.details}>
          <div className={styles.patientProfile}>
            <img src={userAvatar} alt="" />
            <div className={styles.user}>
              <h1>
                {`${user?.firstName} 
                ${user?.lastName}`}
              </h1>
              <span>Patient</span>
            </div>
          </div>
          {shouldCreateNewCarePlan && (
            <button
              onClick={() => {
                navigate('/create-care-plan');
              }}
            >
              Create Care Plan
            </button>
          )}
          {shouldContinueCarePlan && !shouldAllowContinue ? (
            <Tooltip
              title="care plan still in progress by another practitioner"
              color="#6a451a"
              key={'#6a451a'}
            >
              <button disabled={true} onClick={handleContinue}>
                Continue Care Plan
              </button>
            </Tooltip>
          ) : shouldAllowContinue && shouldContinueCarePlan ? (
            <button onClick={handleContinue}>Continue Care Plan</button>
          ) : null}

          {!shouldCreateNewCarePlan && !shouldContinueCarePlan && (
            <button
              onClick={() => {
                dispatch(
                  setCurrentCarePlan({
                    ...lastCarePlan,
                    isCareplanSent: false,
                    isCareplanCompleted: false,
                    hasStripePaymentBeenMade: false,
                    hasCareplanClaimBeenBilled: false,
                    stepsCompleted: {
                      stepFiveCompleted: false,
                      stepFourCompleted: false,
                      stepOneCompleted: false,
                      stepThreeCompleted: false,
                      stepTwoCompleted: false,
                    },
                    status: 'IN_PROGRESS',
                    flow: 'modify',
                  })
                );
                navigate('/create-care-plan');
              }}
            >
              Modify Care Plan
            </button>
          )}
        </div>
      </div>
      <DetailsInformation
        userInfo={user}
        doctorInfo={lastCarePlan}
        lastCareplanDate={lastCareplanDate}
        careplanId={lastCarePlan?._id || lastCarePlan?.id}
        carePlanHistory={careplanHistory}
      />
      <DetailsGoal
        shortTermGoals={assessment?.shortTermGoal?.message}
        longTermGoals={assessment?.longTermGoal}
        carePlanHistory={careplanHistory}
        asrs={asrs}
      />
      <DetailsCareTeam careTeam={careTeam} patientNotes={careplanHistory} />
      {/* <ReferalNoteAndAppointment referralNote={{}} /> */}
    </div>
  );
};

export default DashboardUserDetails;
