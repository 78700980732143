import React, { useState } from 'react';
import './FormInput.scss';

const Input = ({ label, className, name, type, hasError, value, onChange }) => {
  const [isActive, setIsActive] = useState(false);
  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handleInputBlur = () => {
    setIsActive(value !== '');
  };

  return (
    <div
      className={`floating-input ${isActive ? 'active' : ''} ${
        hasError ? 'error' : ''
      } ${className}`}
    >
      <input
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <label>{label}</label>
    </div>
  );
};

export default Input;

export const Textarea = ({
  label,
  className,
  name,
  hasError,
  value,
  onChange,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handleInputBlur = () => {
    setIsActive(value !== '');
  };

  return (
    <div
      className={`floating-input ${isActive ? 'active' : ''} ${
        hasError ? 'error' : ''
      } ${className}`}
    >
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <label>{label}</label>
    </div>
  );
};
