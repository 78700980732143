import React, { useEffect, useState } from 'react';
import styles from './medicareBilling.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextPlanStep } from '../../../../redux/slices/carePlanSlice';
import GoBackButton from '../../../GoBackButton/GoBackButton';
import CustomInput from '../../../CustomInput/CustomInput';
import Modal from '../../../Modal/Modal';
import { api } from '../../../../api.js';
import alertIcon from '../../../../assets/icons/AlertCircle.svg';
import successIcon from '../../../../assets/icons/CheckCircle.svg';
import bellIcon from '../../../../assets/logo/bell-yellow.svg';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import moment from 'moment';
import { updateBillingType } from 'redux/slices/currentCarePlan';

const MedicareBilling = () => {
  const { user } = useSelector((state) => state.carePlanSummary) || {};

  const [confirmModal, setConfirmModal] = useState(false);
  const [proceedConfirmationModal, setProceedConfirmationModal] =
    useState(false);

  const [userChoice, setUserChoice] = useState(null);

  const { billingDetails } =
    useSelector((state) => state.currentCarePlan) || {};
  const [loading, setLoading] = useState(false);
  const [loadingConfirmation, setLoadingConfirmation] = useState(false);

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [medicareId, setMedicareId] = useState(user?.medicareNum || '');

  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [gender, setGender] = useState(user?.sex || '');
  const [dateOfBirth, setDateOfBirth] = useState(
    user?.dateOfBirth ? moment(user.dateOfBirth).format('YYYY-MM-DD') : ''
  );
  const [medicareReferralNumber, setMedicareReferralNumber] = useState(
    user?.medicareReferralNumber || ''
  );
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const active = useSelector((state) => state.carePlan.activePlanStep) || 0;

  useEffect(() => {
    if (active) {
      dispatch(handleNextPlanStep({ step: 3 }));
    }
  }, [active]);

  const handleVerify = async () => {
    try {
      setLoading(true);
      const requestData = {
        medicareNum: medicareId,
        firstName,
        lastName,
        sex: gender,
        dateOfBirth,
        medicareReferralNumber,
      };

      const response = await api.post('/claims/verify-patient', requestData);

      if (response.data?.data?.code <= 0) {
        setSuccessMessage(response.data.message);
        setShowSuccessPopup(true);
        setConfirmModal(true);
      } else {
        setErrorMessage(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred while processing the request.');
      }

      setShowErrorPopup(true);
      setProceedConfirmationModal(true);
    }
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <div>
        <GoBackButton to={-1} />
      </div>
      <div className={styles.contents}>
        {showSuccessPopup && (
          <div className={styles.successContainer}>
            <img src={successIcon} alt="" />
            <p>{successMessage}</p>
            <div role="button" onClick={() => setShowSuccessPopup(false)}>
              X
            </div>
          </div>
        )}
        {showErrorPopup && (
          <div className={styles.errorContainer}>
            <img src={alertIcon} alt="" />
            <div>
              <h1>Error </h1>
              <p>{errorMessage}</p>
            </div>
            <div
              onClick={() => setShowErrorPopup(false)}
              className={styles.errorCancel}
            >
              X
            </div>
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>Medicare Billing</h1>
            <div>
              <h2>1. Verify Patient</h2>
            </div>
          </div>
          <div className={styles.content}>
            <CustomInput
              label="Type of billing:"
              name="type"
              value={billingDetails?.type}
              onChange={(value) => dispatch(updateBillingType(value))}
            />
            <CustomInput
              type="number"
              value={medicareId}
              onChange={setMedicareId}
              name="medicareNumber"
              label="Medicare Number:"
            />
            <CustomInput
              name="firstName"
              label="First Name:"
              value={firstName}
              onChange={(value) => setFirstName(value)}
            />
            <CustomInput
              name="LastName"
              label="LastName:"
              value={lastName}
              onChange={(value) => setLastName(value)}
            />
            <CustomInput
              className={styles.dropdown}
              label="Gender:"
              name="Gender"
              value={gender}
              onChange={(value) => setGender(value)}
            />
            <CustomInput
              name="DateOfBirth"
              label="Date of Birth:"
              value={dateOfBirth}
              onChange={(value) => setDateOfBirth(value)}
            />
            <CustomInput
              type="number"
              name="ref"
              label="IRN(Reference Number):"
              value={medicareReferralNumber}
              onChange={(value) => setMedicareReferralNumber(value)}
            />
          </div>
        </div>
        <div className={styles.done}>
          {showSuccessPopup ? (
            <button
              onClick={() => navigate('/create-care-plan/medicare-claims')}
            >
              Continue
            </button>
          ) : (
            <div>
              <Button
                onClick={handleVerify}
                label="Verify"
                isLoading={loading}
              />
            </div>
          )}
        </div>
      </div>

      {billingDetails.formattedBillingType === 'Medicare' && (
        <Modal isOpen={confirmModal} onClose={() => setConfirmModal(false)}>
          <div className={styles.confirmModal}>
            <img src={bellIcon} alt="" />
            <h1>Payment confirmation</h1>
            <p>
              Payment has been charged to the patient. Was the payment made in
              full?
            </p>
            <div className={styles.modalButtons}>
              <button
                onClick={() => {
                  setUserChoice('No');
                  navigate('/create-care-plan/medicare-claims', {
                    state: { userChoice },
                  });
                }}
              >
                No
              </button>

              <button
                onClick={() => {
                  setUserChoice('Yes');
                  navigate('/create-care-plan/medicare-claims', {
                    state: { userChoice },
                  });
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      )}

      {proceedConfirmationModal && (
        <Modal isOpen={true} onClose={() => setProceedConfirmationModal(false)}>
          <div className={styles.confirmModal}>
            <img src={bellIcon} alt="" />
            <h1>Confirmation</h1>
            <p>Do you want to proceed despite the error?</p>
            <div className={styles.modalButtons}>
              <button onClick={() => setProceedConfirmationModal(false)}>
                No
              </button>
              <button
                onClick={() => navigate('/create-care-plan/medicare-claims')}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MedicareBilling;
