import React, { createContext, useContext, useState } from 'react';

const BillingContext = createContext();

export const BillingProvider = ({ children }) => {
  const [apiResponse, setApiResponse] = useState(null);

  const setBillingResponse = (response) => {
    setApiResponse(response || {});
  };

  return (
    <BillingContext.Provider value={{ apiResponse, setBillingResponse }}>
      {children}
    </BillingContext.Provider>
  );
};

export const useBillingContext = () => useContext(BillingContext);
