import React from 'react';
import styles from './customCheckbox.module.scss';

const CustomCheckbox = () => {
  return (
    <label className={styles.customCheckbox}>
      <input type="checkbox" />
      <span class={styles.checkmark}></span>
    </label>
  );
};

export default CustomCheckbox;
