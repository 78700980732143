import React from 'react';
import styles from './carePlanNav.module.scss';
import cpLogo from '../../../assets/logo/cp-365.svg';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Assessment from '../Assessment/Assessment';
import CreatePlan from '../CreatePlan/CreatePlan';
import CreateCareSchedule from '../CreateCareSchedule/CreateCareSchedule';
import CreateCareBilling from '../CreateCareBilling/CreateCareBilling';
import BillingAmount from '../CreateCareBilling/amount/BillingAmount';
import MedicareBilling from '../CreateCareBilling/MedicareBilling/MedicareBilling';
import MedicareClaims from '../CreateCareBilling/medicareClaims/MedicareClaims';
import BillingFilled from '../CreateCareBilling/billingFilled/BillingFilled';
import BillingSummary from '../BillingSummary/BillingSummary';
import PatientNote from '../PatientNote/PatientNote';

const CarePlanNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<CarePlanTopContent />}>
        <Route index path="/" element={<Assessment />} />
        <Route path="/plan" element={<CreatePlan />} />
        <Route path="/schedule" element={<CreateCareSchedule />} />
        <Route path="/billing" element={<CreateCareBilling />}></Route>
        <Route path="/amount" element={<BillingAmount />} />
        <Route path="/medicare-billing" element={<MedicareBilling />} />
        <Route path="/medicare-claims" element={<MedicareClaims />} />
        <Route path="/billing-filled" element={<BillingFilled />} />
        {/* <Route path="/billing-summary" element={<BillingSummary />} /> */}
        <Route path="/patient-note" element={<PatientNote />} />
      </Route>
    </Routes>
  );
};

export default CarePlanNavigation;

const CarePlanTopContent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <div className={styles.innerRoot}>
          <img
            className={styles.navLogo}
            src={cpLogo}
            alt=""
            onClick={() => navigate('/dashboard')}
          />
          <ProgressBar />
        </div>
      </div>
      <Outlet />
    </div>
  );
};
