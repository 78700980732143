import { stateToHTML } from 'draft-js-export-html';

export const getHTMLFromEditor = (editorState) => {
  if (editorState === null) return;
  const contentState = editorState.getCurrentContent();
  const html = stateToHTML(contentState);

  return html;
};

export function processString(inputString) {
  const htmlRegex = /<\/?[\w\s="/.':;#-\/\?]+>/gi;
  if (htmlRegex.test(inputString)) {
    return inputString;
  } else {
    return `<p>${inputString}</p>`;
  }
}

export function htmlToPlainText(htmlString) {
  const htmlRegex = /<\/?[\w\s="/.':;#-\/\?]+>/gi;

  if (htmlRegex.test(htmlString)) {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  } else {
    return htmlString;
  }
}
