import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchPatients = async ({
  page,
  limit,
  kycStatus,
  patientName,
}) => {
  try {
    const response = await api.get('/practitioners/patients', {
      params: { page, limit, kycStatus, patientName },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCarePlanSummary = createAsyncThunk(
  'carePlanSummary/fetchCarePlanSummary',
  async (patientId) => {
    try {
      const { data } = await api.get(
        `/careplan/patient-summary?userId=${patientId}`
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const invitePatient = async (data) => {
  try {
    const response = await api.post('/practitioners/invite-patient/', data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
