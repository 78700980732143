import DashboardProfile from '../components/DashboardProfile/DashboardProfile';
import DashboardUsersTable from '../components/DashboardUsersTable/DashboardUsersTable';
import './Dashboard.scss';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <DashboardProfile />
      <DashboardUsersTable />
    </div>
  );
};

export default Dashboard;
