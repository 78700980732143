import React from 'react';
import styles from './Card.module.scss';
import { useNavigate } from 'react-router-dom';

const Card = ({ content, title, viewAllLink, icon, showViewAll = true }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            {icon && <img src={icon} alt="icon" />}
            <h1>{title}</h1>
          </div>
          {showViewAll && (
            <span onClick={() => navigate(viewAllLink)}>{'View all >'} </span>
          )}
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

export default Card;
