import moment from 'moment';
import React from 'react';

const CurrentPatientsTable = ({ data, navigate, defaultProfilePicture }) => {
  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Patient</th>
          <th style={{ fontFamily: 'Avenir' }}>Age</th>
          <th style={{ fontFamily: 'Avenir' }}>Location</th>
          <th style={{ fontFamily: 'Avenir' }}>Recent K10 Score</th>
          <th style={{ fontFamily: 'Avenir' }}>Most Recent Visit</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({
            firstName,
            lastName,
            dateOfBirth,
            id,
            _id,
            city,
            assessmentDate,
            recentScore,
            imageUrl,
          }) => (
            <tr className="table-row" key={id || _id}>
              <td>
                <div className="custom-table-image">
                  <img
                    src={defaultProfilePicture}
                    alt={imageUrl}
                    className=""
                  />
                  <p>{`${firstName} ${lastName}`}</p>
                </div>
              </td>
              <td>
                {dateOfBirth ? moment().diff(dateOfBirth, 'years') : 'N/A'}
              </td>
              <td>{city}</td>
              <td>{recentScore}</td>
              <td>{moment(assessmentDate).format('LL')}</td>
              <td>
                <div>
                  <button
                    onClick={() =>
                      navigate(`/dashboard/user-details/${id || _id}`)
                    }
                    className="table-details-button"
                  >
                    See Details
                  </button>
                </div>
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
};

export default CurrentPatientsTable;
