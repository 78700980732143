import React, { useState } from 'react';
import AsrsQuestion from 'components/Asrs/AsrsQuestions';
import userAvatar from '../assets/logo/userAvatar.svg';
import styles from '../pages/AsrsQuestionPage.module.scss';
import GoBackButton from 'components/GoBackButton/GoBackButton';
import { useNavigate } from 'react-router-dom';
import Responsedivs from 'components/Asrs/AsrsResponse';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from 'api';
import { Spin } from 'antd';
import moment from 'moment';

const AsrsQuestionPage = () => {
  const navigate = useNavigate();

  const { user, asrs } = useSelector((state) => state.carePlanSummary) || {};

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const selectedAsrs = asrs || [].find((asr) => asr._id === id);

  const { data, error, isLoading, isValidating } =
    useSWR([`/assessment/asrs/${id}`], fetcher) || {};

  return (
    <div className={styles.root}>
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles['ant-spin-dot-item']} />
        </div>
      )}
      <GoBackButton to={-1} />
      <div className={styles.patientDetails}>
        <h1 className={styles.patientHeader}>
          Adult ADHD Self-Care Report Scale
        </h1>
        <div className={styles.details}>
          <div className={styles.patientProfile}>
            <img src={userAvatar} alt="" />
            <div className={styles.user}>
              <h1>{user?.firstName + ' ' + user?.lastName}</h1>
              <span>{moment(selectedAsrs.createdAt).format('LL')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.questionsContainer}>
        <div className={styles.left_question_div}>
          {!isLoading &&
            data.map((question, index) => (
              <AsrsQuestion
                key={index}
                title={question.title}
                question={question.question}
              />
            ))}
        </div>
        <div className={styles.right_question_div}>
          <div className={styles.answerBtn}>
            {!isLoading &&
              data.map((question, index) => {
                return (
                  <Responsedivs
                    key={index}
                    question={question}
                    answer={question.answerKey}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsrsQuestionPage;
