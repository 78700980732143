import { useState } from 'react';

import addIcon from '../../../../assets/logo/add.svg';
import styles from './createMedication.module.scss';
import CustomSelectDropdown from 'components/CustomSelectDropdown/CustomSelectDropdown';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setMedication } from 'redux/slices/currentCarePlan';

const CreateMedication = ({ medicationList, closeModal }) => {
  const meds = useSelector((state) => state.currentCarePlan.medication);
  const [medications, setMedications] = useState(meds || []);

  const handleAddFields = () => {
    setMedications([...medications, { drugName: '', dosage: '' }]);
  };
  const dispatch = useDispatch();

  const handleChangeMedicationValues = (i, e, type) => {
    setMedications((prevMedications) => {
      const newFormValues = [...prevMedications];
      if (type === 'drugName') {
        newFormValues[i] = { ...newFormValues[i], drugName: e };
      } else {
        newFormValues[i] = { ...newFormValues[i], dosage: e };
      }
      return newFormValues;
    });
  };

  const handleDeleteMedication = (id) => {
    const filteredFormValue = medications.filter((item, index) => index !== id);
    setMedications(filteredFormValue);
  };

  const handleDone = () => {
    dispatch(setMedication({ medication: medications }));
    closeModal();
  };

  const transformedOption = medicationList.map((option, idx) => ({
    ...option,
    key: idx,
    value: option.drugName,
    label: (
      <div>
        <div style={{ marginBottom: '2px', whiteSpace: 'pre-wrap' }}>
          {option.drugName}
        </div>
        <div>{option.description}</div>
      </div>
    ),
  }));
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Patient Medication</h1>
        <span>Prescribe medication and dose for this patient.</span>
      </div>
      <div className={styles.medicationContainer}>
        {medications.map((element, index) => (
          <div key={index} className={styles.medication}>
            <div className={styles.label}>
              <p>Medication {index + 1}</p>
              <div
                onClick={() => handleDeleteMedication(index)}
                className={styles.delete}
              >
                delete
              </div>
            </div>
            <div className={styles.label}>
              <div style={{ width: '50%' }}>
                <span>Select medication</span>
                <CustomSelectDropdown
                  value={element.drugName}
                  showSearch={true}
                  data={transformedOption}
                  onChange={(e) =>
                    handleChangeMedicationValues(index, e, 'drugName')
                  }
                />
              </div>

              <div style={{ width: '40%' }}>
                <span>Indicate dose</span>
                <input
                  onChange={(e) =>
                    handleChangeMedicationValues(index, e.target.value, 'dose')
                  }
                  name="dose"
                  value={element.dosage}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div onClick={handleAddFields} className={styles.add}>
        <img src={addIcon} alt="" />
        <span>Add Medication</span>
      </div>
      <div className={styles.done}>
        <Button label={'Done'} type={'button'} onClick={handleDone} />
      </div>
    </div>
  );
};

export default CreateMedication;
