import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextPlanStep } from '../../../../redux/slices/carePlanSlice';
import styles from './amount.module.scss';
import GoBackButton from '../../../GoBackButton/GoBackButton';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import successIcon from '../../../../assets/icons/CheckCircle.svg';
import alertIcon from '../../../../assets/icons/AlertCircle.svg';

import { api } from 'api';
import { useBillingContext } from './billingContext';

import Button from 'components/Button';
import { updateBillingDetails } from 'redux/slices/currentCarePlan';

const BillingAmount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setBillingResponse } = useBillingContext();

  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const active = useSelector((state) => state.carePlan.activePlanStep) || 0;
  const { patient, _id, billingDetails } =
    useSelector((state) => state.currentCarePlan) || {};
  const careplanId = _id;
  const { user } = useSelector((state) => state.carePlanSummary) || {};
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showContinueButton, setShowContinueButton] = useState(false);

  useEffect(() => {
    if (active) {
      dispatch(handleNextPlanStep({ step: 3 }));
    }

    if (
      (billingDetails?.amount && billingDetails?.type === 'Medicare') ||
      (billingDetails?.formattedBillingType === 'CardNoClaim' &&
        billingDetails?.amount)
    ) {
      setAmount(billingDetails.amount);
      setShowContinueButton(true);
      setSuccessMessage('Payment successful');
    }
  }, [active]);

  console.log('billingDetails:', billingDetails);

  const handleContinue = async (e) => {
    try {
      setLoading(true);
      const formData = {
        amount,
        careplan: careplanId,
        user: user?.id,
        billingType: 'Medicare',
      };
      const response = await api.post('/cards/create-charge', formData);

      if (response.data.status === true) {
        const { data } = response.data || {};
        setBillingResponse({
          responseData: response.data,
          userEnteredAmount: amount,
        });
        dispatch(
          updateBillingDetails({
            amount: data.amount,
            receipt: data.receiptUrl,
            stripeChargeId: data.stripeChargeId,
          })
        );
        setSuccessMessage(response.data.message);
        setShowContinueButton(true);
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred');
      console.log('Error making post request:', error);
      setLoading(false);
    }
  };

  const handleContinueClick = () => {
    if (billingDetails?.formattedBillingType === 'CardNoClaim') {
      navigate('/create-care-plan/billing-filled');
    } else if (billingDetails?.formattedBillingType === 'Medicare') {
      navigate('/create-care-plan/medicare-billing');
    }
  };

  const handleAmountChange = (event) => {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(event.target.value)) {
      setAmount(event.target.value);
    }
  };

  return (
    <div className={styles.root}>
      <div>
        <GoBackButton to={-1} />
      </div>

      <div className={styles.container}>
        {successMessage && (
          <div className={styles.successMessage}>
            <img src={successIcon} alt="" />
            <p>{successMessage}</p>
            <div
              onClick={() => setSuccessMessage(false)}
              className={styles.errorCancel}
            >
              X
            </div>
          </div>
        )}
        {errorMessage && (
          <div className={styles.errorMessage}>
            <img src={alertIcon} alt="" />
            <p>{errorMessage}</p>
            <div
              onClick={() => setErrorMessage(false)}
              className={styles.errorCancel}
            >
              X
            </div>
          </div>
        )}
        <div className={styles.content}>
          <h1>Patient Billing</h1>
          <p>Specify amount to be paid by the patient</p>
          <form>
            <label>Amount</label>
            <div className={styles.amount}>
              <div>
                <span>AUD</span>
              </div>
              <input
                disabled={showContinueButton}
                type="number"
                step="0.01"
                value={amount}
                onChange={handleAmountChange}
                style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
              />
            </div>

            {showContinueButton ? (
              <Button
                className={styles.amountBtn}
                onClick={handleContinueClick}
                type="button"
                label="Continue"
                isLoading={loading}
                style={{
                  fontSize: '20px',
                  fontFamily: 'Avenir',
                }}
              />
            ) : (
              <Button
                className={styles.amountBtn}
                onClick={handleContinue}
                type="button"
                label="Bill"
                isLoading={loading}
                style={{
                  fontSize: '20px',
                  fontFamily: 'Avenir',
                }}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default BillingAmount;
