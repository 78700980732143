import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import styles from './invitePatient.module.scss';
import successLogo from '../../../assets/logo/ok.svg';
import ModalInput from '../../Modal/ModalInput/ModalInput';
import FormItem from 'antd/es/form/FormItem';
import PhoneInput from 'antd-phone-input';
import { invitePatient } from '../../../redux/actions/patientAction';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import Button from '../../Button';

const InvitePatient = ({ closeModal }) => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    // Define your validation rules for each input field
    firstName: Yup.string().required(),
    email: Yup.string().email().required(),
    lastName: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: (formData) => {
      setLoading(true);
      formData.phoneNumber = `+${phoneNumber?.countryCode}${phoneNumber?.areaCode}${phoneNumber?.phoneNumber}`;
      formData.formattedNumber = `${phoneNumber?.areaCode}${phoneNumber?.phoneNumber}`;
      invitePatient(formData)
        .then((res) => {
          setLoading(false);
          setDisplaySuccess(true);
        })
        .catch((err) => {
          setLoading(false);
          toast.error('There was an error inviting this patient');
        });
    },
  });

  return displaySuccess ? (
    <div className={styles.successRoot}>
      <img src={successLogo} alt="" />
      <h1>Your invitation is on the way!</h1>
      <span>
        {`You have successfully sent an invitation to ${`${formik.values.firstName} ${formik.values.lastName}`} .`}
      </span>
      <button
        type="button"
        onClick={() => closeModal(false)}
        className={styles.inviteButton}
      >
        Close
      </button>
    </div>
  ) : (
    <div className={styles.root}>
      <div className={styles.text}>
        <h1>Invite a Patient</h1>
        <p>
          Provide patient’s name, phone number and email address to send an
          invite.
        </p>
      </div>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <ModalInput
          className={styles.inputWidth}
          label="First Name"
          name="firstName"
          value={formik.values.first}
          onChange={formik.handleChange}
          type="text"
        />
        <ModalInput
          className={styles.inputWidth}
          label="Last Name"
          name="lastName"
          value={formik.values.last}
          onChange={formik.handleChange}
          type="text"
        />
        <ModalInput
          className={styles.inputWidth}
          label="Email"
          name="email"
          value={formik.values.email}
          type="email"
          onChange={formik.handleChange}
        />
        <div className={styles.phoneNumberInput}>
          <label>Phone Number</label>

          <FormItem name="phone">
            <PhoneInput enableSearch onChange={setPhoneNumber} />
          </FormItem>
        </div>
        <Button
          label="Send Invite"
          type="submit"
          disabled={!formik.isValid}
          isLoading={loading}
        />
      </form>
    </div>
  );
};

export default InvitePatient;
