import React from 'react';
import moment from 'moment';

const PendingPatientsTable = ({ data, navigate, defaultProfilePicture }) => {
  return (
    <table>
      <thead>
        <tr className="table-head">
          <th style={{ textAlign: 'start', fontFamily: 'Avenir' }}>Patient</th>
          <th style={{ fontFamily: 'Avenir' }}>Email</th>
          <th style={{ fontFamily: 'Avenir' }}>Phone</th>
          <th style={{ fontFamily: 'Avenir' }}>Date Invited </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((patient) => (
          <tr className="table-row" key={patient?.id || patient._id}>
            <td>
              <div className="custom-table-image">
                <img
                  src={defaultProfilePicture}
                  alt={patient?.firstName}
                  className=""
                />
                <p>{`${patient?.firstName} ${patient?.lastName}`}</p>
              </div>
            </td>
            <td>{patient?.email}</td>
            <td>{patient?.phoneNumber}</td>
            <td>{patient.recentScore}</td>

            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PendingPatientsTable;
