import { logout } from '../slices/authSlice';

const tokenExpirationMiddleware = (store) => (next) => (action) => {
  const tokenData = JSON.parse(localStorage.getItem('token'));

  if (tokenData && tokenData.expiresAt < new Date().getTime()) {
    store.dispatch(logout());

    window.location.href = '/';
  }
  return next(action);
};

export default tokenExpirationMiddleware;
