import React from 'react';

const YearPicker = ({ selectedYear, setSelectedYear, className }) => {
  // Helper function to generate a range of years in descending order
  const generateYears = (start, end) => {
    const years = [];
    for (let year = end; year >= start; year--) {
      years.push(year);
    }
    return years;
  };

  // Generate a list of years (e.g., from the current year to 1900)
  const yearsList = generateYears(1900, new Date().getFullYear());

  // Event handler for year selection
  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
  };

  return (
    <div>
      <select
        className={className}
        id="yearPicker"
        onChange={handleYearChange}
        value={selectedYear || ''}
      >
        <option value="">Select Year</option>
        {yearsList.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearPicker;
