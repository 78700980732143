import { api } from 'api';

export const createCarePlan = async (payload) => {
  try {
    delete payload._id;
    return api.post(`/careplan/create`, payload);
  } catch (error) {
    throw error;
  }
};

export const updateCarePlan = async (careplanId, data) => {
  return api.post(`/careplan/create?careplanId=${careplanId}`, data);
};
export const sendToPatient = async (careplanId, userId) => {
  return api.post(`/careplan/send?careplanId=${careplanId}&userId=${userId}`);
};

export const getBillingTransaction = async (chargeId) => {
  return api.get(`/cards/transaction-details/${chargeId}`);
};
