import React, { useEffect, useState } from 'react';
import styles from './billing.module.scss';
import searchIcon from '../../assets/icons/search.svg';
import YearPicker from '../../utils/getYear';
import CustomCheckbox from '../../components/customCheckBox/CustomCheckbox';
import BillingTable from './billingTable/BillingTable';
import DatePicker from 'react-date-picker';
import useDebounce from './useDebounce';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { useLocation } from 'react-router-dom';

const Billings = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [searchInput, setSearchInput] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const debouncedSearchInput = useDebounce(searchInput, 1000);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const claimId = searchParams.get('claimId');
  const locationId = searchParams.get('locationId');

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>Billing</h1>
      <div className={styles.options}>
        <div className={styles.searchContainer}>
          <img src={searchIcon} alt="" />
          <input
            type="search"
            placeholder="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div className={styles.filterContainer}>
          {showDropdown && (
            <div className={styles.filterDropdown}>
              <h1>Filter By Date of Care Plan Creation</h1>
              <YearPicker
                className={styles.select}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
              <h1>Filter By Date of Billing</h1>
              {/** TODO DATE FIELD */}
              <div className={styles.date}>
                <span>Start Date</span>
                <DatePicker onChange={setStartDate} value={startDate} />
              </div>
              <div className={styles.date}>
                <span>Finish Date</span>
                <DatePicker onChange={setFinishDate} value={finishDate} />
              </div>
              <div className={styles.statusFilter}>
                <h1>Filter By Status</h1>
                <div className={styles.progress}>
                  <p>In progress</p>
                  <CustomCheckbox />
                </div>
                <div className={styles.success}>
                  <p>Successful</p>
                  <CustomCheckbox />
                </div>
                <div className={styles.failed}>
                  <p>Failed</p>
                  <CustomCheckbox />
                </div>
              </div>
              <div className={styles.filterButtons}>
                <button>Clear</button>
                <button>Filter</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <BillingTable
        searchInput={debouncedSearchInput}
        claimId={claimId}
        locationId={locationId}
      />
    </div>
  );
};

export default Billings;
