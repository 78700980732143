import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  appointment: {
    appointmentDate: null,
    meetingLink: '',
  },
  requests: [],
  assessment: {
    shortTermGoal: {
      message: '',
      reductionLevel: '',
      period: '',
    },
    longTermGoal: '',
    k10questions: {
      first: 0,
      second: 0,
      third: 0,
      fourth: 0,
      fifth: 0,
      sixth: 0,
      seventh: 0,
      eighth: 0,
      ninth: 0,
      tenth: 0,
    },
    level: '',
    rating: 0,
    score: 0,
    stressors: {},
  },
  assessmentDescription: '',
  diagnosis: [],
  hasCareplanClaimBeenBilled: false,
  hasStripePaymentBeenMade: false,
  homework: [],
  isCareplanCompleted: false,
  billingType: null,
  billingDetails: {
    amount: null,
    type: null,
    stripeChargeId: null,
    receipt: null,
    hasCareplanClaimBeenBilled: false,
    hasPatientBeenVerified: false,
  },
  isCareplanSent: false,
  medication: [],
  patientNote: '',
  patientNoteDate: null,
  rating: null,
  riskToOthers: '',
  riskToSelf: '',
  status: '',
  stepsCompleted: {
    stepOneCompleted: false,
    stepTwoCompleted: false,
    stepThreeCompleted: false,
    stepFourCompleted: false,
    stepFiveCompleted: false,
  },
  therapy: {
    hasSafetyPlanning: null,
    therapyType: '',
    otherInterventions: '',
  },
  createdAt: null,
  practitioner: {
    firstName: '',
    lastName: '',
    type: '',
    id: null,
    _id: null,
  },
  patient: {
    _id: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    age: null,
    medicareReferralNumber: null,
    dateOfBirth: null,
    medicareNum: null,
    sex: null,
    city: null,
    country: null,
    postalCode: null,
    state: null,
    street: null,
  },
  flow: '',
  hasBeenModified: false,
};

export const resetBillingType = createAsyncThunk(
  'currentCarePlan/resetBillingType',
  async (_, { dispatch }) => {
    dispatch(updateBillingType(null));
  }
);

const currentCarePlanSlice = createSlice({
  name: 'currentCarePlan',
  initialState,
  reducers: {
    setCurrentCarePlan: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    setShortTermGoals: (state, action) => {
      const { assessment } = action.payload || {};
      state = { ...state, ...action.payload };
      if (
        assessment?.shortTermGoal?.period &&
        assessment?.shortTermGoal?.reductionLevel
      ) {
        state.assessment.shortTermGoal.message = `Reduce level of depression & anxiety by ${assessment?.shortTermGoal?.reductionLevel} after a period of  ${assessment?.shortTermGoal?.period}  after starting treatment as measured by K10`;
      }

      return {
        ...state,
        ...action.payload,
      };
    },
    setLongTermGoals: (state, action) => {
      const { assessment } = action.payload || {};
      state.assessment.longTermGoal = assessment?.longTermGoal;
    },

    setMedication: (state, action) => {
      state.medication = action.payload.medication;
    },

    setTherapy: (state, action) => {
      const { therapy } = action.payload || {};
      state.therapy = therapy;
    },
    updateHomeWork: (state, action) => {
      const { homework } = action.payload || {};
      state.homework = homework;
    },
    updateRequest: (state, action) => {
      const { requests } = action.payload || {};
      state.requests = requests;
    },
    updateAppointment: (state, action) => {
      const { appointment } = action.payload || {};
      state.appointment = appointment;
    },
    updatePatientNote: (state, action) => {
      const { patientNote } = action.payload || {};
      state.patientNote = patientNote;
    },
    updateBillingType: (state, action) => {
      state.billingDetails.type = action.payload;
    },
    updateBillingDetails: (state, action) => {
      state.billingDetails = { ...state.billingDetails, ...action.payload };
    },

    resetCurrentCarePlan: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setCurrentCarePlan,
  setShortTermGoals,
  setLongTermGoals,
  setMedication,
  setTherapy,
  updateHomeWork,
  updateRequest,
  updateAppointment,
  updatePatientNote,
  updateBillingType,
  resetCurrentCarePlan,
  updateBillingDetails,
} = currentCarePlanSlice.actions;

export default currentCarePlanSlice.reducer;
