import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import downloadIcon from '../../assets/icons/Download.svg';
import userImage from '../../assets/logo/user.png';
import styles from './patientNote.module.scss';
import { useSelector } from 'react-redux';
import RichEditor from 'components/RichText/RichText';
import { stateToHTML } from 'draft-js-export-html';
import { processString } from 'utils/editorHelpers';
import { convertFromHTML, EditorState, ContentState } from 'draft-js';
import moment from 'moment';

export const PatientNote = () => {
  const navigate = useNavigate();

  const params = useParams();
  let formatted = null;

  const careplanWithPatientNote =
    useSelector((state) => state.carePlanSummary.careplanHistory).find(
      (hist) => hist._id === params.id
    ) || {};

  if (careplanWithPatientNote.patientNote) {
    const parsed = processString(careplanWithPatientNote.patientNote);
    formatted = EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(parsed))
    );
  }

  const [editorState] = useState(formatted);

  const { practitioner, patient } = careplanWithPatientNote || {};

  return (
    <div className={styles.root}>
      <button onClick={() => navigate(-1)} className={styles.backButton} alt="">
        {'< '}
        {'Go Back'}
      </button>
      <div className={styles.header}>
        <h1>Patient Note</h1>
        <div role="button" className={styles.downloadButton}>
          <span>Download as PDF</span>
          <img src={downloadIcon} alt="" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          {/* <img src={note.image} alt="" /> */}
          <h1>{practitioner?.firstName + ' ' + practitioner?.lastName}</h1>
          <span>
            {moment(careplanWithPatientNote?.patientNoteDate).format('LL')}
          </span>
        </div>
        <div className={styles.right}>
          <h1>Patient: {patient?.firstName + ' ' + patient?.lastName}</h1>
          <div className={styles.editedText}>
            <div
              dangerouslySetInnerHTML={{
                __html: stateToHTML(editorState?.getCurrentContent()),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
