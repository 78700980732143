import React, { useState, useEffect } from 'react';
import { api } from '../../api';
import Modal from '../Modal/Modal';
import styles from './paymentDisplayCard.module.scss';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

const PaymentDisplayCard = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [url, setUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth) || {};

  const isBankAccountConnected = user?.isBankAccountConnected;

  useEffect(() => {
    setIsConnected(isBankAccountConnected);
  }, [isBankAccountConnected]);

  const redirectToStripe = async () => {
    try {
      setLoading(true);
      const response = await api.post('/cards/connect-stripe-account');
      const responseData = response.data;

      if (responseData.status === true) {
        setUrl(responseData?.data?.url);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.log('Error getting Stripe Data', error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const proceedToStripe = (e) => {
    try {
      setLoading(true);
      e.preventDefault();

      if (url) {
        window.location.href = url;
        closeModal();
      } else {
        console.error('Error: The URL is undefined.');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error: The URL is undefined.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles['payment_container']}>
      {loading ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.95)',
            zIndex: 1000,
          }}
        >
          <Spin tip="Loading..." className={styles['ant-spin-dot-item']} />
        </div>
      ) : null}
      <div className={styles['payment_header']}>
        <h2>Payment Method</h2>
        <p>Modify your payment method here.</p>
      </div>
      <div className={styles['payment_items']}>
        <div className={styles['payment_items_header']}>
          <h2 className={styles['payment_items_h1']}>Stripe</h2>
          <p>Connect your Stripe account to process payments easily</p>
        </div>
        <button
          className={`${styles.btn} ${isConnected ? styles.connected : ''}`}
          disabled={isConnected}
          onClick={redirectToStripe}
        >
          {isConnected ? <>Connected</> : 'Connect'}
        </button>
      </div>

      {/* Modal for Stripe Connect */}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        children={
          <div className={styles['modal-Root']}>
            <h2 className={styles['modal_h2']}>Stripe Connect</h2>
            <p className={styles['modal_p']}>Proceed to stripe</p>
            <Button
              onClick={proceedToStripe}
              type="submit"
              isLoading={loading}
              label="Proceed"
              style={{ width: '50%', marginLeft: '80px' }}
            />
          </div>
        }
      />
    </div>
  );
};

export default PaymentDisplayCard;
