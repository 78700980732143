import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  MedicareResponseData: null,
};

const medicareClaimsSlice = createSlice({
  name: 'medicareClaims',
  initialState,
  reducers: {
    setMedicareResponseData: (state, action) => {
      state.MedicareResponseData = action.payload;
    },
  },
});

export const { setMedicareResponseData } = medicareClaimsSlice.actions;

export default medicareClaimsSlice.reducer;
