import React from 'react';
import greyCheck from '../assets/icons/grey-check.png';
import greenCheck from '../assets/icons/green-check.png';
import './StatusIndicator.scss';

const StatusIndicator = ({ text, state }) => {
  return (
    <div className="status-indicator">
      <div className={`status-icon ${state}`}>
        {state === 'green' && <img src={greenCheck} alt="Green Tick" />}
        {state === 'grey' && <img src={greyCheck} alt="Grey Tick" />}
      </div>
      <span>{text}</span>
    </div>
  );
};

export default StatusIndicator;
