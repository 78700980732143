import React from 'react';
import styles from './sidebar.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import profileIcon from '../../assets/logo/account.svg';
import securityIcon from '../../assets/logo/history.svg';
import billIcon from '../../assets/logo/billing.svg';
import locationIcon from '../../assets/logo/Location.svg';
import CalendarIcon from '../../assets/logo/calendar.svg';
import { updateAccountTab } from 'redux/slices/accountSlice';

const tabData = [
  { id: 0, icon: profileIcon, label: 'Profile', name: 'profile' },
  { id: 1, icon: securityIcon, label: 'Security', name: 'security' },
  { id: 2, icon: billIcon, label: 'Payment', name: 'payment' },
  { id: 3, icon: locationIcon, label: 'Location', name: 'location' },
  // { id: 4, icon: CalendarIcon, label: 'Calendar', name: 'calendar' },
];

const Sidebar = () => {
  const { currentTab } = useSelector((state) => state.account) || {};
  const dispatch = useDispatch();
  const handleProfileSection = (tabName) => {
    dispatch(updateAccountTab({ tab: tabName }));
  };

  return (
    <div className={styles.sidebar_wrapper}>
      {tabData.map((tab) => (
        <button
          key={tab.id}
          className={styles.profile_item}
          onClick={() => handleProfileSection(tab.name)}
          // Add a class conditionally based on the current tab
          className={
            currentTab === tab.name
              ? styles['profile_item'] + ' ' + styles['active']
              : styles['profile_item']
          }
        >
          <img src={tab.icon} alt={`${tab.label}_icon`} />
          <p>{tab.label}</p>
        </button>
      ))}
    </div>
  );
};

export default Sidebar;
