import React from 'react';
import './icon.scss';

const patientIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    className="icon-svg"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16699 23.8335C2.16699 19.047 6.04719 15.1668 10.8337 15.1668C15.6202 15.1668 19.5003 19.047 19.5003 23.8335H17.3337C17.3337 20.2437 14.4235 17.3335 10.8337 17.3335C7.24381 17.3335 4.33366 20.2437 4.33366 23.8335H2.16699ZM10.8337 14.0835C7.24241 14.0835 4.33366 11.1747 4.33366 7.5835C4.33366 3.99225 7.24241 1.0835 10.8337 1.0835C14.4249 1.0835 17.3337 3.99225 17.3337 7.5835C17.3337 11.1747 14.4249 14.0835 10.8337 14.0835ZM10.8337 11.9168C13.2278 11.9168 15.167 9.97766 15.167 7.5835C15.167 5.18933 13.2278 3.25016 10.8337 3.25016C8.43949 3.25016 6.50033 5.18933 6.50033 7.5835C6.50033 9.97766 8.43949 11.9168 10.8337 11.9168ZM19.8077 15.9282C22.8201 17.2859 24.917 20.3148 24.917 23.8335H22.7503C22.7503 21.1945 21.1777 18.9229 18.9184 17.9045L19.8077 15.9282ZM19.0629 3.69781C21.2276 4.59028 22.7503 6.72074 22.7503 9.2085C22.7503 12.3179 20.3715 14.8691 17.3337 15.1426V12.9618C19.1718 12.6992 20.5837 11.1195 20.5837 9.2085C20.5837 7.71279 19.7187 6.42003 18.4614 5.80288L19.0629 3.69781Z"
      className="icon-svg"
    />
  </svg>
);

export default patientIcon;
